import React, { useState } from "react";
import { getBlogByParam, getRandom } from "../../../utils/utils";
import { articles } from "../../../data/data";
import InfoBar from "./InfoBar";
import "./Post.css";
import likeButton from "../../../assets/blogs/heart-like-filled.png";
import notLikedButton from "../../../assets/blogs/heart-like-empty.png";
import BlogsListCard from "../BlogsListCard";

export default function HowToManageAnxiety({ url_param }) {
  const [isLiked, setIsLiked] = useState(false);
  const { title, imageURL, author, dateUploaded } = getBlogByParam(
    articles,
    url_param
  );
  const randomNum = getRandom(11);
  const handleLikeClick = () => setIsLiked((p) => !p);

  return (
    <div className="post">
      <div className="post-container">
        <h1 className="post-heading">{title}</h1>
        <InfoBar author={author} dateUploaded={dateUploaded} />
        <img src={`${imageURL}`} alt="blog-cover" />
        <summary className="content-container">
          {/* <!-- image here --> */}
          <div className="section">
            <h2 className="section-title">Understand Social Anxiety Disorder</h2>
            <p className="section-content">
              Anxiety is a natural instinct in all human beings that tell us
              when danger is close and when we should run away. However, when
              this instinct goes wrong, like a compass gone haywire, it flares
              up in random and unexpected moments, making it a disorder. In the
              case of social anxiety disorder, the normal awkwardness in crowds
              becomes incomparable terror.
            </p>
            <p className="section-content">
              By learning and considering your behavior as a result of anxiety
              disorder rather than an inborn part of yourself, you can begin to
              distance yourself from your anxious thoughts and start sorting
              rational reactions from the irrational ones.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Find A Grounding Point</h2>
            <p className="section-content">
              Grounding is a technique that helps keep you in the present and
              helps reorient you to the here-and-now and to reality. When
              anxiety fills your head with too many thoughts to function,
              grounding yourself can allow your mind those moments of respite
              that let you see the surroundings with clarity.
            </p>
            <p className="section-content">
              There are many ways to do this. One example includes visualizing
              your anxious thoughts as a physical energy and using any way you
              like to expel them out of your space. It may seem ineffective but
              with practice and patience, it turns out to be a really handy
              technique in a clutch.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">
              Verbalize and Rationalize Your Thoughts
            </h2>
            <p className="section-content">
              When our thoughts strut around in our minds and scream about how
              “everyone here thinks you look stupid!”, it can get very difficult
              to function normally, let alone do any added tasks. An anxious
              mind’s inner critics have megaphones and drowning them out is not
              easy.
            </p>
            <p className="section-content">
              The solution to this? Give them a voice. The statement “All the
              people in the room are looking at you!” sounds terrifying in your
              head but out loud, it seems a little silly. Beyond this,
              rationalizing can help you put that thought into perspective.‘Are
              you looking at anyone? If not, why would others look at you?’ And
              so on, until you find a reasonable explanation.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Be Around People</h2>
            <p className="section-content">
              Anxiety disorders, like many other mental health issues, thrive in
              isolation. Your anxious instincts tell you to stay at home, turn
              off your phone, refuse the party invitation and become a social
              hermit. The more you try to appease those instincts, the more you
              move away from getting over this.
            </p>
            <p className="section-content">
              You may start small by just existing around people before trying
              to integrate yourself into their circles. Be it malls, gatherings
              or house parties. It is only by staying around people can you
              ensure that you are in a path of progress.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Fake It Till You Make It</h2>
            <p className="section-content">
              This may seem redundant but owning confidence and self assurance
              requires the know-how of socializing. To do this, one has to
              integrate in social situations with or without being confident.
              The fear of embarrassment isn’t always irrational, but all it does
              is tell us how to do better.
            </p>
            <p className="section-content">
              This is no doubt the scariest yet most important step in all. The
              lines between faking social and being social will blur quickly and
              you will be on your path to living your dream life.
            </p>
          </div>
        </summary>
      </div>
      <div className="post-bottom">
        <div className="likes">
          {isLiked ? (
            <button onClick={handleLikeClick}>
              <img src={likeButton} alt="heart-filled-button" width={30} />
            </button>
          ) : (
            <button onClick={handleLikeClick}>
              <img src={notLikedButton} alt="heart-empty-button" width={30} />
            </button>
          )}
        </div>
        <h2 style={{ fontWeight: 200 }}>More</h2>
        {articles.slice(randomNum, randomNum + 3).map((item, index) => {
          return (
            <BlogsListCard
              author={item.author}
              dateUploaded={item.dateUploaded}
              imageURL={item.imageURL}
              title={item.title}
              url_param={item.url_param}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}
