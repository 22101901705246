
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { FieldValue, getFirestore, serverTimestamp, increment } from "firebase/firestore";
import { getAuth } from "firebase/auth"; 

const firebaseConfig = {
  apiKey: "AIzaSyBqUGCy10QPmldKsujIedP3gw4_2suPHyM",
  authDomain: "curomates-uat.firebaseapp.com",
  projectId: "curomates-uat",
  storageBucket: "curomates-uat.appspot.com",
  messagingSenderId: "40762441913",
  appId: "1:40762441913:web:5d7ae8933df48a96a09b68",
  measurementId: "G-QCRF0W5V6N",
};

// firebase.initializeApp(firebaseConfig);

// firebase.firestore().settings({ experimentalForceLongPolling: true });

// export default firebase;

const app = initializeApp(firebaseConfig);

export const fireDB = getFirestore(app);
export const fireAuth = getAuth(app);
// export const fireStorage = firebase.storage();
export {serverTimestamp, increment };
