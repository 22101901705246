/* User Action Types */
export const SET_USER = "SET_USER";
export const SET_MEMBER_PROFILE = "SET_MEMBER_PROFILE";
export const SET_LISTENER_PROFILE = "SET_LISTENER_PROFILE";
export const CLEAR_USER = "CLEAR_USER";

/* Expo Push Token */

export const SET_EXPO_PUSH_TOKEN = "SET_EXPO_PUSH_TOKEN"
export const CLEAR_EXPO_PUSH_TOKEN = "CLEAR_EXPO_PUSH_TOKEN"

/* FCM Push Token */

export const SET_FCM_PUSH_TOKEN = "SET_FCM_PUSH_TOKEN"
export const CLEAR_FCM_PUSH_TOKEN = "CLEAR_FCM_PUSH_TOKEN"

/* Unread Messages */

export const SET_MESSAGE_WATCHER = "SET_MESSAGE_WATCHER"
export const CLEAR_MESSAGE_WATCHER = "CLEAR_MESSAGE_WATCHER"

/* Unread Notifications */

export const SET_NOTIFCATION_WATCHER = "SET_NOTIFICATION_WATCHER"
export const CLEAR_NOTIFICATION_WATCHER = "CLEAR_NOTIFICATION_WATCHER"

/* Setup Global Modals */

export const DISPLAY_SIGNUP_MODAL = "DISPLAY_SIGNUP_MODAL"
export const DISPLAY_APP_REVIEW_MODAL = "DISPLAY_APP_REVIEW_MODAL"
export const DISPLAY_APP_UPDATE_MODAL = "DISPLAY_APP_UPDATE_MODAL"
export const DISPLAY_MOOD_TRACKER_MODAL = "DISPLAY_APP_MOOD_TRACKER_MODAL"
export const DISPLAY_MASCOT_MODAL = "DISPLAY_MASCOT_MODAL"
export const DISPLAY_SURVEY_MODAL = "DISPLAY_SURVEY_MODAL"
export const DISPLAY_MISSED_CALL_MODAL = "DISPLAY_MISSED_CALL_MODAL"
export const DISPLAY_CREDIT_OFFER_MODAL = "DISPLAY_CREDIT_OFFER_MODAL"
export const DISPLAY_RECEIVED_CALL_MODAL = "DISPLAY_RECEIVED_CALL_MODAL"
/* Miscellaneous Data */

export const UPDATE_TIME_FOR_APP_UPDATE_CHECK = "UPDATE_TIME_FOR_APP_UPDATE_CHECK"
export const USER_ADDED_REVIEW = "USER_ADDED_REVIEW"
export const UPDATE_TIME_FOR_APP_REVIEW_MODAL_SHOWN_CHECK = "UPDATE_TIME_FOR_APP_REVIEW_MODAL_SHOWN_CHECK"
export const UPDATE_RECENT_LISTENER_REQUESTS_TIMESTAMPS = "UPDATE_RECENT_LISTENER_REQUESTS_TIMESTAMPS"
export const UPDATE_RECENT_POST_TIMESTAMPS = "UPDATE_RECENT_POST_TIMESTAMPS"
export const SET_LISTENER_PROFILE_IN_PROGRESS = "SET_LISTENER_PROFILE_IN_PROGRESS"
export const BAN_USER_FROM_APP = "BAN_USER_FROM_APP"
export const SET_GUIDELINES_VIOLATION = "SET_GUIDELINES_VIOLATION"
export const SET_GUIDELINES_VIOLATION_LIST = "SET_GUIDELINES_VIOLATION_LIST"

export const UPDATE_MRBEAKS_CHAT = "UPDATE_MRBEAKS_CHAT"

export const UPDATE_DAILY_MOOD_STREAKS = "UPDATE_DAILY_MOOD_STREAKS"
export const ALLOW_CALL_WITH_INVITATION = "ALLOW_CALL_WITH_INVITATION"


/* Music Player States */

export const SET_PLAYING = "SET_PLAYING"
export const SET_PLAYBACK_OBJ = "SET_PLAYBACK_OBJ"
export const SET_SOUND_OBJ = "SET_SOUND_OBJ"
export const SET_CURRENT_AUDIO = "SET_CURRENT_AUDIO"
export const SET_PLAYBACK_POSITION = "SET_PLAYBACK_POSITION"
export const SET_PLAYBACK_DURATION = "SET_PLAYBACK_DURATION"
export const SET_CURRENT_POSITION = "SET_CURRENT_POSITION"
export const RESET_MUSIC_PLAYER = "RESET_MUSIC_PLAYER"


/* App Credits */
export const SET_MEMBER_CREDITS = "SET_MEMBER_CREDITS";
export const SET_LISTENER_CREDITS = "SET_LISTENER_CREDITS";
export const CLEAR_CREDITS = "CLEAR_CREDITS";


export const SET_ONGOING_CALL = "SET_ONGOING_CALL"
export const CLEAR_ONGOING_CALL = "CLEAR_ONGOING_CALL"