import React from "react";
import { useParams } from "react-router-dom";
import { checkForParamCategory, getBlogsByCategory, revertLinkParam } from "../../utils/utils";
import "./CategoriesPage.css";
import { Link } from "react-router-dom";
import BlogsListCard from "../../components/blogs/BlogsListCard";
import NewsletterComponent from "../../components/common/NewsletterComponent";
import { articles, categories } from "../../data/data";

const NewsLetterCard = () => {
  return (
    <div className="blogs-category-newsletter-card">
      <h3>Want to receive thought provoking monthly updates?</h3>
      <NewsletterComponent />
    </div>
  );
};

const MainCard = ({ title, author, dateUploaded, desc, imageURL }) => {
  return (
    <div className="blogs-category-page-main-card">
      {/* IMAGE PLACEHOLDER */}
      <div className="" style={{ backgroundImage: `url(${imageURL})`, backgroundSize:'cover' }}></div>
      <section>
        <h4>
          {author} • {dateUploaded}
        </h4>
        <h1>{title}</h1>
        <summary>{desc}</summary>
      </section>
    </div>
  );
};

export default function CategoriesPage() {
  const { category } = useParams();
  const formattedCategoryStr = revertLinkParam(category);
  const blogs = getBlogsByCategory(categories,articles,category)

  if(checkForParamCategory(categories, category)){
    return <div>Category not found</div>
  }

  return (
    <div className="blogs-category-page">
      <section className="blogs-category-page-container">
        {/* Category Tag */}
        <h3>{formattedCategoryStr}</h3>

        <div>
          <h1>Writings from our team</h1>
          <Link to="/blogs">Back to categories &gt;</Link>
        </div>
        <section className="blogs-category-main-grid">
          {/* MAIN CARD */}
          <Link to={`/blogs/posts/${blogs[0].url_param}`}>
          <MainCard
            title={blogs[0].title}
            author={blogs[0].author}
            dateUploaded={blogs[0].dateUploaded}
            imageURL={blogs[0].imageURL}
            desc="Let us take a quick look back on a time well spent, with a monthly recap of all events and partnerships made during the month of ..."
          />
          </Link>
          {/* LIST OF CATEGORY SPECIFIC BLOGS */}
          <div className="blogs-category-list">
            {blogs.slice(1,4).map((item, index) => {
              return (
                <BlogsListCard
                  author={item.author}
                  dateUploaded={item.dateUploaded}
                  imageURL={item.imageURL}
                  title={item.title}
                  url_param={item.url_param}
                  key={index}
                />
              );
            })}
          </div>
        </section>

        <h1>All blog posts</h1>
        <div className="blogs-category-all">
          {blogs.map((item, index) => {
            return (
              <BlogsListCard
                author={item.author}
                dateUploaded={item.dateUploaded}
                imageURL={item.imageURL}
                title={item.title}
                url_param={item.url_param}
                key={index}
              />
            );
          })}
        </div>
      </section>
    </div>
  );
}
