import { Slider } from '@mui/material'
import React, { useState } from 'react'
import "./EmployeeImpact.css"
import employeeImpactImage from "../../assets/corporate/employeeImpact.png"

export default function EmployeeImpact() {

  const [employeeCount, setemployeeCount] = useState(10)
  const [employeeSuffering, setemployeeSuffering] = useState(5)
  const [daysLost, setdaysLost] = useState(30)
  const [monetaryLoss, setmonetaryLoss] = useState("6,54,000")


    const valuetext = (value) => {
        return `${value}`;
      }
     
    const handleEmployeeValueChange = (employeeCount) => {

      setemployeeCount(employeeCount)
      setemployeeSuffering(Math.round(employeeCount*0.48))
      setdaysLost(Math.round(employeeCount*0.48*7))
      setmonetaryLoss(Math.round(calculateAnnualLoss(employeeCount)).toLocaleString('en-IN'))
    }

    const calculateAnnualLoss = (employeeCount) => {
      // Updated placeholder values for the sake of example (in INR)
      const averageSickDaysPerEmployee = 5;
      const averageDailyWage = 1000; // Average daily wage in INR
      const affectedEmployees = employeeCount*0.48;
      const percentageOfPresenteeism = 0.10;
      const averageAnnualSalary = 500000; // Average annual salary in INR
      const turnoverPercentage = 0.05;
      const averageCostOfReplacingEmployee = 20000; // Average cost of replacing an employee in INR
      const averageCostPerEmployeeForHealthcare = 5000; // Average healthcare cost per employee in INR
      const costPerEmployeeForTraining = 1000; // Cost per employee for training in INR
      const legalFees = 20000; // Legal fees in INR
      const settlements = 30000; // Settlements in INR
      const fines = 10000; // Fines in INR
  
      const absenteeismCosts = averageSickDaysPerEmployee * averageDailyWage * affectedEmployees;
      const presenteeismCosts = percentageOfPresenteeism * employeeCount * averageAnnualSalary;
      const turnoverCosts = turnoverPercentage * employeeCount * averageCostOfReplacingEmployee;
      const healthcareCosts = averageCostPerEmployeeForHealthcare * employeeCount;
      const trainingCosts = costPerEmployeeForTraining * employeeCount;
      const legalAndComplianceCosts = legalFees + settlements + fines;
  
      const totalAnnualLoss = absenteeismCosts + presenteeismCosts + turnoverCosts +
        healthcareCosts + trainingCosts + legalAndComplianceCosts;
  
      return totalAnnualLoss;
    };

    const marks = []
    marks[0] = {value:10}

    const startStep = 50

    for(let i=1;i<=100;i++)
    {
      const newStep = startStep*i
      marks.push({value:newStep})
    }

  return (
    <div className="hero-container">
      <div className="ei-container">
        <div className="ei-container-text ei-container-text-heading">
          How many employees are in your organization?
        </div>
        <Slider
          aria-label="Employee"
          defaultValue={10}
          value={employeeCount}
          onChange={(event, employeeCount) => {
            handleEmployeeValueChange(employeeCount);
          }}
          getAriaValueText={valuetext}
          valueLabelDisplay="auto"
          step={null}
          marks={marks}
          min={10}
          max={5000}
          style={{ width: "60%", color: "#153A56", margin: "1rem" }}
        />
      </div>

      <div className="ei-main-section">
        <div className="ei-content">
          <div className="ei-content-number">{employeeSuffering}</div>
          <div className="ei-container-text">
            <span> colleagues are currently</span>
            <span>struggling with some form of</span>
            <span>mental health issue</span>
          </div>

          <div className="m-space"></div>

          <div className="ei-content-number">{daysLost}</div>
          <div className="ei-container-text">
            <span> days lost per year due to mental</span>
            <span>health issues alone</span>
          </div>

          <div className="m-space"></div>

          <div className="ei-content-number">₹{monetaryLoss}</div>
          <div className="ei-container-text">
            <span> the annual loss due to mental</span>
            <span>health issues in the workplace</span>
          </div>
        </div>

        <div className="ei-image-container">
          <span className="ei-image-container-text">
            Personalized sessions with
          </span>
          <span className="ei-image-container-text">our team of experts</span>
          <img src={employeeImpactImage} className="ei-image" />
        </div>
      </div>
    </div>
  );
}
