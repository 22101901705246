import React from 'react'
import { motion } from "framer-motion";
import "./GetInTouch.css"

export default function GetInTouch() {

    const textVariant = {
        offScreen: {
          y: "150px",
          opacity: 0,
        },
        onScreen: {
          y: 0,
          opacity: 1,
          transition: {
            duration: 1,
          },
        },
      };

  return (
    <div className='get-in-touch-container'>
        <motion.div className="git-button--wrapper" variants={textVariant}>
        <a
          href='#contact-us'
          rel="noopener noreferrer"
          className="git-cta"
        >
          <span className="git-cta-text" >
            Get in touch
          </span>
        </a>
        <span className='git-text'>
           Today
          </span>

        {/* </a> */}
      </motion.div>

      <div className='git-cta-sub-text'>Get started in days not months</div>
    </div>
  )
}
