import React from "react";
import { convertToLinkParam } from "../../utils/utils";
import { Link } from "react-router-dom";
import "../../pages/blogs/BlogsPage.css"

export default function BlogsListCard({ title, author, dateUploaded, imageURL, url_param }) {
  const link = convertToLinkParam(title);
  return (
    <Link to={`/blogs/posts/${url_param}`} style={{textDecoration:"none"}} className="blogs-list-card-link">
      <div className="blogs-list-card">
        {/* IMAGE PLACEHOLDER */}
        <div className="" style={{ backgroundImage: `url(${imageURL})`, backgroundSize:'cover' }} ></div>
        <section>
          <h4>
            {author} • {dateUploaded}
          </h4>
          <h1>{title}</h1>
        </section>
      </div>
    </Link>
  );
}
