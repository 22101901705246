import React from "react";
import { Link } from "react-router-dom";
import { convertToLinkParam } from "../../utils/utils";
import { categories } from "../../data/data";
import allBg from "../../assets/blogs/blog-categories/all.jpg";
import socializingBg from "../../assets/blogs/blog-categories/socializing.jpg";
import relationshipsBg from "../../assets/blogs/blog-categories/relationships.jpg";
import dealingWithEmotionsBg from "../../assets/blogs/blog-categories/emotions.jpg";
import exams from "../../assets/blogs/blog-categories/exams.jpg";
import routine from "../../assets/blogs/blog-categories/routines.jpg";

const imageDeterminer = (linkParam) => {
  switch (linkParam) {
    case "socializing-and-loneliness":
      return socializingBg;
      break;
    case "relationships":
      return relationshipsBg;
      break;
    case "dealing-with-your-emotions":
      return dealingWithEmotionsBg 
      break;
    case "exams-and-work-stress":
      return exams 
      break;
    case "daily-routines-and-confidence":
      return routine;
      break;
    default:
      return allBg
      break;
  }
};

const CategoryCard = ({ categoryName }) => {
  const linkParam = convertToLinkParam(categoryName);
  return (
    <Link
      to={`/blogs/${linkParam}`}
      className="blogs-category-section-card-link"
    >
      <div
        style={{
          backgroundImage: `url(${imageDeterminer(linkParam)})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="blogs-category-section-card"
      >
        <h1>{categoryName}</h1>
      </div>
    </Link>
  );
};

export default function CategoriesSection() {
  return (
    <>
      <h1 className="blogs-category-section-heading">
        Select a Category to Explore
      </h1>
      <div className="blogs-category-section">
        {categories.map((item, idx) => (
          <CategoryCard key={idx} categoryName={item.categoryName} />
        ))}
      </div>
    </>
  );
}
