export const faqData = [
    {
      question: "What is Curomates?",
      answer:
        "Curomates is a community-powered application that helps you improve your mental health. Venting about problems really helps, so you can talk to volunteer listeners anonymously, anytime. You can also track your mental health, listen to relaxing music, guided meditation routines and much more. Come and be a part of a wholesome and inclusive community!",
    },
    {
      question: "Can I talk to psychologists? ",
      answer:
        "Yes! You can book sessions with psychologists via the Curomates app! ",
    },
    {
      question: "How much does Curomates cost? ",
      answer:
        "We have a variety of features ranging from free to ₹ 250. You can also book sessions with psychologists for ₹499 or even audio call listeners at very affordable rates. Of course, there will always be a free component!",
    },
    {
      question: "I can get a job at Curomates?",
      answer: "Please checkout out LinkedIn page for any postings that open up!",
    },
    {
      question: "Can Curomates help me in a crisis?",
      answer:
        "Curomates is not designed to help people may be at danger of harming themselves or others. We provide users with alternate crisis helpline numbers within the app.",
    },
    {
      question: "Who created Curomates?",
      answer:
        "Curomates was created by a group of friends who were tired of seeing people struggle to get the help that they needed with their mental health problems and decided to do something about it!",
    },
    {
      question: "Does Curomates have an iOS application?",
      answer: "We do not at the moment, but it is coming very soon!",
    },
  ];



  export const TherapyFaqData = [
    {
      
      question: "How does therapy work?",
      answer:
        "Therapy can help you work to identify and break concerning patterns or habits on your own. Psychologists don't give you all the answers. They help you find the right tools to help yourself. Going to therapy for the first time can make a lot of people nervous. That's normal! If your psychologist does something that makes you feel uncomfortable, it's okay to speak up or just find another psychologist. But try to stick with it for a few sessions before you give up altogether!",
    },
    {
      
      question: "How to find the right psychologist? ",
      answer:
        "If you've been meeting with your psychologist for a while and still aren't seeing any benefit, it's time to reevaluate. It may be that you just need to open up more... Or it might not be a great fit. Even a really good psychologist might not be the best psychologist for you. Don't be afraid to switch psychologists if it seems like you need a different approach.",
    },
    {
      question: "How many sessions do I need?",
      answer:
        "With therapy, there usually is no set length of treatment. Therapy can last anywhere from one session to several months or even years. It all depends on what you want and need. Some people come to therapy with a very specific problem they need to solve and might find that one or two sessions is sufficient. Other people come to therapy with more complicated issues and may need a few months or more.",
    },
    {
      question: "Can psychologists prescribe medication?",
      answer: "Our psychologists cannot recommend or order medications, but they can refer you for evaluation for medication or other treatments. However, unlike psychologists, psychiatrists are medical doctors, or physicians, with a degree in medicine, and can prescribe medication.",
    },
    {
      question: "Can I cancel or reschedule my session?",
      answer:
        "You can reschedule or cancel your session once as long as there are more than 24 hours before the session starts. If for some reason our psychologist cannot make it to the session, he/she will try to reschedule a session with your approval. If you both can't decide upon a new time, you will get a full refund.",
    },
    {
      question: "How to prepare for my first session?",
      answer:
        "Relax and don't worry too much. Make sure you have a good internet connection and are free from any distractions!",
    },
    
  ];