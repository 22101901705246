import React from 'react'
import Hero from '../components/home/Hero'
import Featured from '../components/home/Featured'
import Information from '../components/home/Information'
import ImageRedirect from '../components/home/ImageRedirect'
import Faq from '../components/home/Faq'
import QuickLinks from '../components/home/QuickLinks'
import ImageBanners from '../components/home/ImageBanners'
import "./homepage.css"
import wpimg from "../assets/hero-image/whatsapp 18.png"
import { NewFeaturedPage } from '../components/home/NewFeaturedPage'
import NewHeroPage from '../components/home/NewHeroPage'
export default function HomePage() {
  return (
    <div className="home">
      <button  onClick={() => window.open("https://wa.me/919653267768?text=Hello", "_blank")} className='floating-wp-btn'>Chat with us <img src={wpimg} height={30} width={30} /></button>
      <NewHeroPage/>
      <Featured />
      <NewFeaturedPage/>
      <ImageBanners />
      <Faq />
      <QuickLinks />
     
    </div>
  );
}
