import React, { useState } from "react";
import { getBlogByParam, getRandom } from "../../../utils/utils";
import { articles } from "../../../data/data";
import InfoBar from "./InfoBar";
import "./Post.css";
import BlogsListCard from "../BlogsListCard";
import likeButton from "../../../assets/blogs/heart-like-filled.png";
import notLikedButton from "../../../assets/blogs/heart-like-empty.png";

export default function AStepByStepGuideToProcessingYourEmotions({
  url_param,
}) {
  const { title, imageURL, author, dateUploaded } = getBlogByParam(
    articles,
    url_param
  );
  const [isLiked, setIsLiked] = useState(false);
  const randomNum = getRandom(11);
  const handleLikeClick = () => setIsLiked((p) => !p);

  return (
    <div className="post">
      <div className="post-container">
        <h1 className="post-heading">{title}</h1>
        <InfoBar author={author} dateUploaded={dateUploaded} />
        <img src={`${imageURL}`} alt="blog-cover" />
        {/* MAIN CONTENT */}
        <summary className="content-container">
          <div className="section">
            
            {/* image here */}
            <div className="section">
              <p className="section-content">
                There is no better introduction to a piece about processing
                emotions than one that calls attention to the longstanding
                repercussions of suppressing your emotions. So, here’s a tiny
                recap of part one of this series that covers “What Suppressing
                Your Emotions Can Mean for You”. It is most likely that several
                of us were raised in households that posited rapid and
                unhindered growth and prosperity as the ultimate objective of
                life. As a consequence, we value getting by, moving forward, and
                not dwelling on emotional obstacles that seek to dwindle our
                pace. We may momentarily pay heed to them, but the need to
                prioritize emotional well-being as chiefly as educational or
                occupational growth is usually unheard of. Since the former does
                not bring fruits as tangible as the latter, we push it aside to
                figure out on some later occasion.
              </p>

              <p className="section-content">
                But the truth is, choosing to process your emotions rather than
                suppress them can help you be socially present, express your
                emotional needs to loved ones, understand and be there for
                yourself, and seek out healthy, long-term solutions to emotional
                adversities. These results are anything but intangible.
              </p>

              <p className="section-content">So, how do we begin?</p>
            </div>
            <div className="section">
              <h2 className="section-title">
                Step 1: Unlearn — There are No “Bad” Emotions
              </h2>
              <p className="section-content">
                Emotions like anger, sadness, fear, and envy are, with good
                reason, undesirable. They aren’t pleasant to experience and we
                look forward to feeling less of them, to live with greater
                happiness and contentment. Over time, this undesirability has
                overlaid these emotions with a negative connotation, turning
                them into “bad emotions” that we must avoid or quickly overcome.
                In consequence, we neglect emotions that need to be experienced
                and processed just as much as happiness or excitement. When
                pushed aside, they pile up until there is no option but to spill
                over and wreak havoc in ways you can’t control.
              </p>

              <p className="section-content">
                The truth is, there is no inherent goodness or badness to
                emotions — they are just automatic physiological reactions to
                external stimuli. We have no power over them, and ascribing a
                negative meaning to them does nothing to change the fact either.
                Experiencing frustration is just as important for your emotional
                well-being as is experiencing joy. They are authentic
                expressions of your body’s response to situations of varying
                nature. Let them flow naturally, and without socially attributed
                curtailment. In the long run, this is what will get you farther
                in your pursuit of growth; not bogging your emotions down for
                being unwelcome.
              </p>
            </div>
            <div className="section">
              <h2 className="section-title">
                Step 2: Identify — Label Your Emotions and Recognize Suppressive
                Patterns
              </h2>
              <p className="section-content">
                When you find yourself feeling unpleasant about something,
                pause. Don’t push away the new rush of physical and emotional
                sensations. Stay with them and direct your attention towards
                locating them. Ask yourself the following questions:
              </p>

              <ul className="section-list">
                <li>What sensations am I experiencing right now?</li>
                <li>In which part(s) of my body am I feeling them?</li>
                <li>
                  What thoughts am I having that indicate what I’m feeling?
                </li>
                <li>
                  Am I allowing myself to have these thoughts or am I
                  contradicting and pushing them away?
                </li>
                <li>
                  Am I experiencing the urge to draw away from these feelings?
                  Why?
                </li>
              </ul>

              <p className="section-content">
                Now that you have gained some awareness of your physical
                experience of the emotion, where it is placed, and how you are
                choosing to respond to its emergence, you can advance to the
                next step — acceptance.
              </p>
            </div>
            <div className="section">
              <h2 className="section-title">
                Step 3: Accept — Staying With the Emotion and Accepting its
                Presence
              </h2>
              <p className="section-content">
                While it might be relatively straightforward to take notice of
                the physical manifestation an emotion presents, holding onto it
                in order to work through it is no easy feat. This is especially
                because you might have so many preconceptions around letting
                those emotions flood you that seem immensely daunting. Not all
                of these are rational or based in truth. So for this stage, try
                to approach the emotion with curiosity rather than premeditated
                dread. Explore the emotion a bit further with these questions:
              </p>

              <ul className="section-list">
                <li>How severe does this feel on a scale of 1 to 10?</li>
                <li>Does this emotion feel bearable?</li>
                <li>Why might it feel too much to tolerate?</li>
                <li>
                  What can I do to make this feel more bearable? (sit down,
                  breathe deeply, relax, etc.)
                </li>
              </ul>
            </div>
            <div className="section">
              <h2 className="section-title">
                Step 4: Consider — What Might be the Cause of This Emotion?
              </h2>
              <p className="section-content">
                This stage requires some time to introspect and mull over the
                possible roots of the emotion. It involves considering multiple
                angles to what might be causing your distress, to allow for it
                to be suitably addressed in the next step. Use these questions
                as a framework:
              </p>

              <ul className="section-list">
                <li>Have I contributed to this sense of distress?</li>
                <li>Are these emotions a consequence of my unmet needs?</li>
                <li>
                  Has someone crossed a boundary with me that’s causing me
                  discomfort?
                </li>
                <li>Has a memory from my childhood been triggered?</li>
                <li>
                  Is this emotion coming from a collection of accumulated
                  events?
                </li>
                <li>
                  Is this feeling a consequence of me pushing the emotion away
                  or judging its emergence?
                </li>
              </ul>

              <p className="section-content">
                Step 5: Addressing — Appeasing the Cause of the Emotion
              </p>

              <p className="section-content">
                This is where we pass the baton on to you; at this stage,
                addressing your emotion entirely depends on what kind of cause
                you were able to narrow down on in the previous stage. Your next
                course of action will ideally work towards confronting,
                negotiating with, or acting on the cause depending on what it
                turns out to be. For instance, if the emotion is coming from
                your needs not being met by a loved one, your next course of
                action would ideally be to reach deeper clarity with them
                regarding working towards meeting them. If the emotion is
                arising from a sense of guilt, you may need to apologize to
                someone that you wronged.
              </p>

              <p className="section-content">
                If any of these steps start to feel too difficult, it’s always
                okay to seek someone else’s help — a trusty friend or even a
                mental health professional who can guide you through the
                process. It will feel tricky initially; it does for us all. But
                over time, taking it one step at a time can go a long way in
                helping you get the hang of it; the results will surely make
                themselves visible in the long run.
              </p>

              <p className="section-content">
                This guide makes substantial references to “How to Process
                Emotions and Feelings” by RecoveryfromAddictionOnline.com.
                https://www.recoveryfromaddictiononline.com/how-to-process-emotions-and-feelings/
              </p>

              <ul className="section-tags">
                <li>Emotions</li>
                <li>Avoidance</li>
                <li>Processing</li>
                <li>Processing Emotions</li>
                <li>Growth</li>
              </ul>
            </div>
          </div>
        </summary>
      </div>

      <div className="post-bottom">
        <div className="likes">
          {isLiked ? (
            <button onClick={handleLikeClick}>
              <img src={likeButton} alt="heart-filled-button" width={30} />
            </button>
          ) : (
            <button onClick={handleLikeClick}>
              <img src={notLikedButton} alt="heart-empty-button" width={30} />
            </button>
          )}
        </div>
        <h2 style={{ fontWeight: 200 }}>More</h2>
        {articles.slice(randomNum, randomNum + 3).map((item, index) => {
          return (
            <BlogsListCard
              author={item.author}
              dateUploaded={item.dateUploaded}
              imageURL={item.imageURL}
              title={item.title}
              url_param={item.url_param}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}
