import React from "react";
import headerImg from "../../assets/about-us/header-about_us.png";

import "./AboutUs.css";
import { dummyTeam } from "../../data/data";
import QuickLinks from "../../components/home/QuickLinks";

const TeamCard = ({ name, designation, description,image }) => {
  return (
    <div className="aboutUs-team-card">
      {/* PFP */}
      <div className="team-info">
        <div className="team-card-pfp">
          <img 
          src={image}
          className="profile-image"
          />
        </div>
        <h2 className="font-weight-bold">{name}</h2>
        <h3>{designation}</h3>
      </div>
      <div className="team-description">{description}</div>
    </div>
  );
};

export default function AboutUs() {
  return (
    <>
      <div className="aboutUs-container">
        <div className="gradient"></div>
        <img src={headerImg} alt="aboutUs" className="headerImg" />
        <summary className="aboutUs-story">
          <h1 className="mb-4">Our Story</h1>
          <p>
        Curomates was founded by a team of passionate individuals who had different background as engineers or management consultants from India's top colleges, but shared a common desire to change the way India deals with mental health challenges. Our journey was inspired by personal experiences witnessing the struggles many face with mental health.
          </p>
        <p>
        We set out to create Curomates—a platform dedicated to breaking down barriers to mental health support and fostering a culture of openness and acceptance. Our mission is to provide accessible and affordable mental wellness resources to all, regardless of background or circumstance. With a dedicated team and a growing community of users, we remain steadfast in our commitment to transforming lives and building a brighter, more inclusive future for mental wellness.
        </p>
        <p>
        Join us as we continue to challenge stigma, expand access to care, and empower individuals to prioritize their mental health and well-being. Together, we can rewrite the narrative surrounding mental health in India and create a more compassionate and supportive society for all. :)
        </p>
        </summary>
        <div className="peach-gradient"></div>
        <div className="team">
          {dummyTeam.map((item, index) => (
            <TeamCard
              description={item.description}
              name={item.name}
              image={item.image}
              designation={item.designation}
              key={index}
            />
          ))}
          <h1>Want to join us?</h1>
        </div>
      </div>
      <QuickLinks />
    </>
  );
}
