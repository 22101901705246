export function convertToLinkParam(string) {
  return string
    .toLowerCase()
    .replace(/[^&\w\s]/gi, "")
    .split(" ")
    .join("-");
}

export function revertLinkParam(string) {
  let result = string.split("-").join(" ");
  result = result.replace(/\bi\b/g, "I");
  result = result.replace(/\b\w/g, (l) => l.toUpperCase());
  return result;
}

export function getFeatured(dataArray){
    return dataArray.filter(article => article.isFeatured)
}

export function getRecentBlogs(dataArray){
    const postCopy = [...dataArray]
    postCopy.sort((a,b)=>{
        const dateA = new Date(a.dateUploaded);
        const dateB = new Date(b.dateUploaded);
        return(dateB - dateA)
    });

    return postCopy.slice(0,4)
}

export function checkForParamCategory(dataArray, paramCategory){
    const newArray = dataArray.filter(category => category.url_param === paramCategory)
    return newArray.length === 0
}

export function getBlogsByCategory(categoriesArray, articlesArray, url_param) {
  // Find the category object with the matching url_param
  const category = categoriesArray.find(cat => cat.url_param === url_param);
  
  // If no matching category is found, return an empty array
  if (!category) return [];
  
  // Get the articleIds from the category object
  const { articleIds } = category;
  
  // Find and return the articles with matching ids
  return articlesArray.filter(article => articleIds.includes(article.aid));
}

export function getBlogByParam(articlesArray, url_param){
    const blog = articlesArray.filter(item => item.url_param === url_param)
    return blog[0]
}

export const getRandom = (limit) => Math.floor(Math.random() * limit)