import React from 'react';
import './CircleComponent.css';

const CircleComponent = ({ index,title,description1,description2,displayLine }) => {
  return (
    <div id='timelineCircle' style={{alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"column",margin:10}}>
      <a className="circle" style={{zIndex:2}}>
        <span>{index}</span>
      </a>
    <div style={{color:"#FFFFFF",marginTop:"0.5rem"}}>{title}</div>
    <div style={{marginTop:"0.5rem",color:"#FFFFFF",textAlign:"center"}}>{description1}</div>
    <div style={{marginTop:"0.3rem",color:"#FFFFFF",textAlign:"center"}}>{description2}</div>

    

    </div>
  );
};

export default CircleComponent;