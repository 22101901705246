import React, { useState } from "react";
import { getBlogByParam, getRandom } from "../../../utils/utils";
import { articles } from "../../../data/data";
import InfoBar from "./InfoBar";
import "./Post.css";
import BlogsListCard from "../BlogsListCard";
import likeButton from "../../../assets/blogs/heart-like-filled.png";
import notLikedButton from "../../../assets/blogs/heart-like-empty.png";

export default function DealingWithGuilt({ url_param }) {
  const { title, imageURL, author, dateUploaded } = getBlogByParam(
    articles,
    url_param
  );
  const [isLiked, setIsLiked] = useState(false);
  const randomNum = getRandom(11);
  const handleLikeClick = () => setIsLiked((p) => !p);

  return (
    <div className="post">
      <div className="post-container">
        <h1 className="post-heading">{title}</h1>
        <InfoBar author={author} dateUploaded={dateUploaded} />
        <img src={`${imageURL}`} alt="blog-cover" />
        {/* MAIN CONTENT */}
        <summary className="content-container">
          <div className="section">
            <blockquote className="section-content" style={{marginBottom:'2rem', fontStyle:'italic'}}>
              Guilt is one of those emotions that feed on itself. With every
              bite it grows heavier. — Robert E. Dunn
            </blockquote>
            <p className="section-content">
              Carrying around feelings of guilt can feel like carrying a huge
              rock twice the size of you. Everyone on the planet has done things
              that they regret. The majority of us experience occasional guilt;
              it’s natural and inherent to being human. Everything from saying
              no to friends or coworkers to cheating on a partner to feeling
              guilty over not spending as much time as we’d like with our loved
              ones. We all react to it differently because we are all different.
            </p>
            <p className="section-content">
              To most, guilt feels heavy and nauseating, and brings with it
              feelings of self-criticism and extreme shame. It can often be
              detrimental to our day-to-day functioning, as your negative
              thoughts affect yourself and those around you. Which makes it
              important to learn how to deal with guilt effectively at the
              earliest.
            </p>
            <p className="section-content">
              Here are a few tips to overcome guilt and start practicing
              acceptance of yourself and others.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Sorting Out Your Feelings</h2>
            <p className="section-content">
              Guilt frequently serves as a cover for other emotions, including
              inadequacy, low self-esteem, envy, and resentment. It might also
              be a result of our limiting ideas. For example, if you frequently
              feel unwarranted guilt, you might think that you are a nasty
              person who doesn’t deserve to be joyful rather than just someone
              who made a mistake. Get in touch with your actual feelings about
              yourself and the world before you can discover the answers to how
              to stop feeling guilty.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Being Forgiving To Yourself</h2>
            <p className="section-content">
              Nobody is entirely good or evil. You don’t have to spend the rest
              of your life apologizing for a minor error. For most
              transgressions, you don’t need to punish yourself so severely.
              Forgiveness has great power. Self-criticism might make us feel
              stuck when it comes to taking action. But when we approach life
              with self-compassion, we see that we are capable of learning from
              our mistakes and overcoming guilt.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Changing The Inner Voice</h2>
            <p className="section-content">
              Do you think of gratitude and positivity or of guilt and
              negativity? Take a moment to listen to your inner dialogue the
              next time you’re trying to figure out how to get rid of guilt.
              Change your negative self-talk to positive affirmations that
              emphasize your positive qualities rather than unreasonable guilt.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Taking Responsibility</h2>
            <p className="section-content">
              If you can recognize that you have hurt someone, you can start by
              actively working to alleviate the situation by apologizing
              sincerely. Depending on the situation, you may express your
              feelings and make sure you keep to your commitment in the future.
              Regardless of the result of such interactions, taking
              responsibility can help fix the moral distress some people
              experience.
            </p>
            <p className="section-content">
              Guilt may feel isolating but it is one of the most common human
              experiences that everyone inevitably goes through. There is a
              lesson in each situation, no matter how distressing or
              debilitating it feels. It only becomes clearer once the heavy
              shroud of guilt fades away.
            </p>
          </div>
        </summary>
      </div>

      <div className="post-bottom">
        <div className="likes">
          {isLiked ? (
            <button onClick={handleLikeClick}>
              <img src={likeButton} alt="heart-filled-button" width={30} />
            </button>
          ) : (
            <button onClick={handleLikeClick}>
              <img src={notLikedButton} alt="heart-empty-button" width={30} />
            </button>
          )}
        </div>
        <h2 style={{ fontWeight: 200 }}>More</h2>
        {articles.slice(randomNum, randomNum + 3).map((item, index) => {
          return (
            <BlogsListCard
              author={item.author}
              dateUploaded={item.dateUploaded}
              imageURL={item.imageURL}
              title={item.title}
              url_param={item.url_param}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}
