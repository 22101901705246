import React, { useState } from "react";
import { getBlogByParam, getRandom } from "../../../utils/utils";
import { articles } from "../../../data/data";
import InfoBar from "./InfoBar";
import "./Post.css";
import BlogsListCard from "../BlogsListCard";
import likeButton from "../../../assets/blogs/heart-like-filled.png";
import notLikedButton from "../../../assets/blogs/heart-like-empty.png";

export default function StrategiesToManageAnger({ url_param }) {
  const { title, imageURL, author, dateUploaded } = getBlogByParam(
    articles,
    url_param
  );
  const [isLiked, setIsLiked] = useState(false);
  const randomNum = getRandom(11);
  const handleLikeClick = () => setIsLiked((p) => !p);

  return (
    <div className="post">
      <div className="post-container">
        <h1 className="post-heading">{title}</h1>
        <InfoBar author={author} dateUploaded={dateUploaded} />
        <img src={`${imageURL}`} alt="blog-cover" />
        {/* MAIN CONTENT */}
        <summary className="content-container">
          <div className="section">
            I recall being a 7-year-old and having a relative vilify my ‘anger’
            and give me unsolicited advice about it. Since then, like many
            others, I have sat on my fury, repressing it. It took me years of
            unlearning, psychology, and the movie ‘Inside Out,’ to realise that
            anger isn’t evil; it’s the actions we choose to commit to when we’re
            angry that can be harmful.
            <p className="section-content">
              Anger is as natural and ordinary as any other emotion, acting as
              an instinctive reaction to being threatened or mistreated.
              However, the consequences of anger can lead to relationship
              problems, health issues, poor work performance, and poor life
              satisfaction.
            </p>
            <p className="section-content">
              Anger is frequently confused with three other terms: bitterness,
              aggression, and hostility; however, we’ll save that discussion for
              another day. A fundamental understanding of prolonged levels and
              intensity of anger is that it will most likely have an impact on
              our bodies, thoughts, behaviours, and overall functioning.
            </p>
            <p className="section-content">
              At some point in our lives, we’ve all learned how to control our
              anger, at least in the short term; I’ll just list a few here as a
              refresher.
            </p>
            <ul className="section-content">
              <li>
                Focused Breathing- Slow and controlled breathing from the belly
                rather than the chest.
              </li>
              <li>
                Counting to 100- Use this technique to distract yourself and
                pace yourself.
              </li>
              <li>
                Muscle Relaxation- Intentional tensing and relaxing of muscles
                throughout the body, from toes to head*.
              </li>
              <li>
                Get Active- Any type of workout can help you decompress and burn
                off extra tension and bodily stress.
              </li>
              <li>
                Distance Yourself- Walking away from a situation allows you to
                calm down and think about something rationally.
              </li>
            </ul>
            Though short-term techniques are beneficial, we need to put in a
            little more effort to reap the long-term benefits.
            <p className="section-content">
              The three easiest strategies for dealing with anger are:
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Recognize and Label Your Anger</h2>
            <p className="section-content">
              Although venting and catharsis are the simplest and most reactive
              ways of releasing anger, they are almost always temporary or
              explosive in nature, and many people are left feeling guilty,
              ashamed, or sad after these practices, which defeats the purpose.
            </p>
            <p className="section-content">
              Instead one can engage in more mindful strategies for a long-term
              benefit, that is to, recognize and label one’s emotions.
            </p>
            <p className="section-content">
              It all starts with being aware of our anger, which allows us to
              understand and resolve it more quickly. A few of the warning signs
              of being worked up are sweating, radiating heat throughout the
              body, pounding heart, teeth grinding, defensiveness, and feeling
              anxious with a heavy chest.
            </p>
            <p className="section-content">
              The steps towards the healthy expression of anger:
            </p>
            <ol className="section-content">
              <li>
                Recognize and Label Anger: “I Am feeling angry
                because_______________________.”
              </li>
              <li>
                Cool off Emotional Brain: Self-Talk, relaxation, breathing,
                walking, etc
              </li>
              <li>
                Engaging the Thinking Brain:
                <ul className="section-content">
                  <li>
                    If the incident that happened is important or unimportant
                    (the weightage it holds in your life)?
                  </li>
                  <li>
                    If it happens to be important, can you control it or not?
                  </li>
                  <li>
                    Is it of utmost importance to you that you control it? With
                    what Strategies, if there are any can you control it with?
                  </li>
                  <li>
                    If the incident is not important, dismiss it and move on to
                    healthier issues.
                  </li>
                </ul>
              </li>
            </ol>
          </div>
          <div className="section">
            <h2 className="section-title">Think-Stop-Act and Time-Out</h2>
            <p className="section-content">
              The whole point of vilifying anger is the consequences of it,
              rather than anger as an emotion in and of itself.
            </p>
            <p className="section-content">
              You probably already know this, and it’s not a sleight of hand,
              but taking a time-out when you’re angry is always a good idea.
            </p>
            <p className="section-content">
              The much-needed time-out not only buys you time but also allows
              you to consider the likely causes of your anger and the potential
              consequences.
            </p>
            <p className="section-content">
              One can hit the pause button by breathing in with intent and
              observing it, engaging in a few forms of exercise, counting to
              ten, walking away from the object/person of anger/conflict,
              keeping quiet and not reacting, distracting yourself by
              visualizing one’s happy place or zoning out or by touching the tip
              of the tongue to the roof of your mouth.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Talk To Yourself</h2>
            <p className="section-content">
              Situations that make you angry are unlikely to make others angry;
              an emotional reaction is heavily dependent on one’s perspective of
              the situation. According to psychology, there are two types of
              thoughts: hot and cool.
            </p>
            <p className="section-content">
              The opposing effects of hot and cool thoughts are that the former
              riles you up and the latter calms you down.
            </p>
            <p className="section-content">For example:</p>
            <p className="section-content">
              Scenario- An old friend of yours crosses you but doesn’t say ‘hi’.
            </p>
            <ul className="section-content">
              <li>Hot Thoughts: How dare this person does not say hi to me!</li>
              <li>
                Cool Thoughts: The person probably didn’t recognize me or had
                something else on their mind.
              </li>
            </ul>
            <p className="section-content">
              Cool thoughts are a way of rationalizing with yourself that your
              anger in this particular situation is unlikely to be
              substantiated. Self-Talk assists one in calming down and not
              becoming agitated as a result of the incident.
            </p>
            <p className="section-content">
              You could think cool thoughts like ‘they probably don’t mean it,’
              ‘they’re probably preoccupied with something,’ ‘take a deep
              breath,’ or ‘don’t let this control you, it’s not worth it.’
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Don’t Brood Over It</h2>
            <p className="section-content">
              Did you wake up irritated about a fight you had with your mother
              three days ago? Do you have hard feelings against a friend who
              forgot your birthday?
            </p>
            <p className="section-content">
              Well, sometimes people engage in ‘rumination,’ or, more
              familiarly, brooding/dwelling over a specific incident. Bringing
              up old injustices only to revisit them with “what ifs” and a few
              alternative scenarios and become enraged all over again. This
              ineffective strategy only causes you to return to the past and
              become enraged, rather than moving on, which is extremely harmful
              to your body, the science of which we will save for another day.
              So my advice, don’t dwell.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Problem-Solving</h2>
            <p className="section-content">
              So you’re agitated because your sister didn’t ask your permission
              before borrowing your favourite piece of clothing for the third
              time in a row? And you still haven’t told her about what’s
              bothering you?
            </p>
            <p className="section-content">
              Bottling up emotions serves no one well, especially in situations
              like the one described above, where it is critical to resolving
              issues as soon as possible.
            </p>
            <p className="section-content">
              Once again, understanding what is happening and why it is
              happening is the first step, followed by a combination of the
              above strategies that work for you. Then you get to how you work
              in order to achieve the desired result.
            </p>
            <p className="section-content">
              For example, in the above scenario, you could make a list of the
              reasons why her borrowing your clothes infuriates you, what could
              help ease the anger triggers, and trial and error to see which
              solution works best.
            </p>
          </div>
        </summary>
      </div>

      <div className="post-bottom">
        <div className="likes">
          {isLiked ? (
            <button onClick={handleLikeClick}>
              <img src={likeButton} alt="heart-filled-button" width={30} />
            </button>
          ) : (
            <button onClick={handleLikeClick}>
              <img src={notLikedButton} alt="heart-empty-button" width={30} />
            </button>
          )}
        </div>
        <h2 style={{ fontWeight: 200 }}>More</h2>
        {articles.slice(randomNum, randomNum + 3).map((item, index) => {
          return (
            <BlogsListCard
              author={item.author}
              dateUploaded={item.dateUploaded}
              imageURL={item.imageURL}
              title={item.title}
              url_param={item.url_param}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}
