import React from "react";
import logo from "../../assets/logo_footer.png";
import "./Footer.css";
import { Link } from "react-router-dom";
import NewsLetterComponent from "./NewsletterComponent";

export default function Footer() {
  return (
    <footer>
      <section className="footer-main">
        {/* Left */}
        <section className="footer-main-left">
          <img src={logo} alt="curomate-logo" />
          <h2>&#169; 2024 Curomates Enterprises Limited</h2>
          <button onClick={()=>window.location.href="https://play.google.com/store/apps/details?id=com.curomates.mobile"} className="footer-download-button">Download App</button>
        </section>

        {/* Center */}
        <section className="footer-main-center">
          <h2>Who We Are</h2>
          <ul className="mt-4">
            <Link to="/about-us" style={{ textDecoration: "none" }}>
              <li>About Us</li>
            </Link>
            <Link to="/blogs" style={{ textDecoration: "none" }}>
              <li>Blog</li>
            </Link>
            <Link to="/faq" style={{ textDecoration: "none" }}>
              <li>FAQ</li>
            </Link>
          </ul>
        </section>

        {/* Right */}
        <section className="footer-main-right">
          <h2>More</h2>
          <ul className="mt-4">
            <Link to="/terms-of-service" style={{ textDecoration: "none" }}>
              <li>Terms of Service</li>
            </Link>
            <Link to="/privacy-policy" style={{ textDecoration: "none" }}>
              <li>Privacy Policy</li>
            </Link>
            <Link to="/refund-policy" style={{ textDecoration: "none" }}>
              <li>Refund Policy</li>
            </Link>
            <Link to="/" style={{ textDecoration: "none" }}>
              <li>Find us</li>
            </Link>
            <Link to="/" style={{ textDecoration: "none" }}>
              <li>at support@curomates.com</li>
            </Link>
          </ul>
        </section>
      </section>
      {/* Bottom */}
      <div className="footer-seperator"></div>
      <div className="footer-bottom">
        <h1>Want to receive thought provoking blogs and monthly updates?</h1>
        <div className="footer-bottom-newsletter">
          <h1 className="m-3">Join our newsletter</h1>
          <NewsLetterComponent />          
        </div>
      </div>
    </footer>
  );
}
