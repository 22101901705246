import React from 'react'
import { useState } from 'react'
import "./Testimonials.css"
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";

export default function Testimonials() {

  const [currentIndex, setcurrentIndex] = useState(1)
  const testimonialList = [
    {
      id : 0,
      content : "Thanks to Curomates, our team's work-life balance has improved. Their programs make a real difference in managing stress. Highly recommended!"
    },
    {
      id : 1,
      content : "Curomates transformed our workplace with practical mental health programs that boost morale. A genuine investment in our people's well-being."
    },
    {
      id : 0,
      content : "Curomates is a true well-being partner. Their mental health programs create a supportive culture, making a meaningful impact on our team's daily lives."
    }

  ]

  return (
    <div className="testimonial-container">

        <div className="testimonial-heading">
            <div className="testimonial-heading-text">Our Partners Love Us</div>
        </div>

        <div className='testimonials'>

          <div className='testimonial-card'>
            <div className='testimonial-card-text'><ImQuotesLeft className='left-quote' />   <span>{testimonialList[currentIndex].content}</span>   <ImQuotesRight className='right-quote' /></div>
          </div>

          <div className='testimonial-card'>
            <div className='testimonial-card-text'><ImQuotesLeft className='left-quote' />   <span>{testimonialList[currentIndex-1].content}</span>   <ImQuotesRight className='right-quote' /></div>
          </div>

          <div className='testimonial-card'>
            <div className='testimonial-card-text'><ImQuotesLeft className='left-quote' />   <span>{testimonialList[currentIndex+1].content}</span>   <ImQuotesRight className='right-quote' /></div>
          </div>

        </div>

     </div>   
  )
}