import React from "react";
import "./Hero.css";
import playStoreIcon from "../../assets/play_store_icon.png";
import appStoreIcon from "../../assets/app_store_icon.png";
import heroImage from "../../assets/corporate/corporate-hero.png";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ToastContainer, toast } from "react-toastify";
import HeroSubSectionCard from "./HeroSubSectionCard";
import corporateCardImage1 from "../../assets/corporate/corp-card-1.png"
import corporateCardImage2 from "../../assets/corporate/corp-card-2.png"
import corporateCardImage3 from "../../assets/corporate/corp-card-3.png"
import corporateCardImage4 from "../../assets/corporate/corp-card-4.png"

const containerVariant = {
  offScreen: {},
  onScreen: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const textVariant = {
  offScreen: {
    y: "150px",
    opacity: 0,
  },
  onScreen: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};

const HeroText = () => {
 

  const notify = () => {
    toast.info("Coming soon!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <motion.div
      className="hero-text"
      initial="offScreen"
      animate="onScreen"
      variants={containerVariant}
    >
     <motion.div className="hero-text-container">
      {/* First Headings */}
      <motion.h1 className="hero-text--heading" variants={textVariant}>Elevating Workplace Well-being with Curomates</motion.h1>

      {/* Description */}
      <motion.h3 className="hero-text--description" variants={textVariant}>
      At Curomates, we understand that a healthy workforce is a thriving workforce.<br/>
      Discover tailored mental health solutions designed to support your employees and enhance overall workplace happiness and productivity
      </motion.h3>

      {/* Store Buttons */}

      {/* Button Wrapper */}
      <motion.div className="hero-button--wrapper" variants={textVariant}>
        <a
          href='#contact-us'
          rel="noopener noreferrer"
          className="corporate-hero-cta"
        >
          <span className="corporate-hero-cta-text" >
            Get in touch
          </span>
        </a>

        {/* </a> */}
      </motion.div>
    </motion.div>


     

    </motion.div>
  );
};

const ImageComponent = () => {
  return (
    <div className="hero-image">
      <LazyLoadImage
        src={heroImage}
        height={600}
        width={400}
        alt="curomates-hero"
        className="hero-image--main"
        loading="lazy"
      />
    </div>
  );
};

export default function Hero() {

  const subSectionCards = [

    {
      id:1,
      image:corporateCardImage1,
      text1:"Expert and Diverse Care",
      text2:"Trusted therapists delivering compassionate care in regional languages"
    },
    {
      id:2,
      image:corporateCardImage2,
      text1:"Customizable Adaptive Programs",
      text2:"Tailored mental health programs evolving with organizational needs for continual customization"
    },
    {
      id:3,
      image:corporateCardImage3,
      text1:"Supportive Ecosystem",
      text2:"Foster a sense of community through support groups and engaging wellness workshops"
    },
    {
      id:4,
      image:corporateCardImage4,
      text1:"Proactive Well-being Tools",
      text2:"Empower employees with practical self-care tools, periodic insights, and flexible scheduling options"
    },

  ]


  return (
    <div className="hero-container">
      <div className="hero">
        <HeroText />
        <ImageComponent />
      </div>

      <motion.div
      initial="offScreen"
      animate="onScreen"
       className="hero-sub-section"
      variants={containerVariant}>

        <motion.div className="hero-sub-section-title" variants={textVariant}>

          Revitalize your team's mental health

        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          className="hero-sub-section-card-container"
        >
          
        {subSectionCards.map((card,index)=>{
            return <HeroSubSectionCard key={index} cardDetails={card} index={index} /> 
        })

        }

        </motion.div>

      </motion.div>
    </div>
  );
}
