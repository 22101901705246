import React from "react";
import linkedinLogo from "../../../assets/blogs/linkedin.png";
import instagramLogo from "../../../assets/blogs/instagram.png";
import twitterLogo from "../../../assets/blogs/twitter.png";
import "./InfoBar.css"
import { FaCircleUser } from "react-icons/fa6";

export default function InfoBar({ author, dateUploaded }) {
  return (
    <div className="post-infoBar">
      {/* PROFILE PICTURE AND NAME AND DATE */}
      <div className="post-infoBar-author">
        {/* PFP */}
        <div>
        <FaCircleUser className="author-profile-pic"/>
        </div>
        <section>
          <h3>{author}</h3>
          <h4>{dateUploaded}</h4>
        </section>
      </div>
      <div className="post-infoBar-share">
        <button>Share</button>
        <a href="http://linkedin.com" target="_blank" rel="noopener noreferrer">
          <button className="linkedin">
            <img src={linkedinLogo} alt="linkedin" width={30} />
          </button>
        </a>
         <a href="http://instagram.com" target="_blank" rel="noopener noreferrer">
          <button className="instagram">
            <img src={instagramLogo} alt="instagram" width={30}/>
          </button>
        </a>
          <a href="http://twitter.com" target="_blank" rel="noopener noreferrer">
          <button className="twitter">
            <img src={twitterLogo} alt="twitter" width={30}/>
          </button>
        </a>
         
      </div>
    </div>
  );
}
