import React, { useState } from "react";
import { getBlogByParam, getRandom } from "../../../utils/utils";
import { articles } from "../../../data/data";
import InfoBar from "./InfoBar";
import "./Post.css";
import BlogsListCard from "../BlogsListCard";
import likeButton from "../../../assets/blogs/heart-like-filled.png";
import notLikedButton from "../../../assets/blogs/heart-like-empty.png";

export default function Your5StepGuideToOvercomingLoneliness({ url_param }) {
  const { title, imageURL, author, dateUploaded } = getBlogByParam(
    articles,
    url_param
  );
  const [isLiked, setIsLiked] = useState(false);
  const randomNum = getRandom(11);
  const handleLikeClick = () => setIsLiked((p) => !p);

  return (
    <div className="post">
      <div className="post-container">
        <h1 className="post-heading">{title}</h1>
        <InfoBar author={author} dateUploaded={dateUploaded} />
        <img src={`${imageURL}`} alt="blog-cover" />
        {/* MAIN CONTENT */}
        <summary className="content-container">
          <div className="section">
            <p className="section-content">
              Let me, for a moment, take you back to the infamous Summer Of
              2020. It’s 3:00 a.m, and the current condition of your
              sleep cycle means that you’re just about beginning your
              day. The days of Ludo games and Zoom parties are far
              behind you, and you have seen every TikTok on the
              internet. You have not seen your friends in ages.
              It’s been so long that you do not even remember what they look
              like. That all too well-known feeling creeps in again, a
              mix of discomfort, insecurity, dejection, loneliness.
            </p>
            <p className="section-content">
              Sounds familiar, doesn’t it? Ironically enough,
              loneliness is probably one of the most universal feelings out
              there, experienced by each and every one of us.
              According to Stephanie Cacioppo, PhD, an assistant professor of
              psychiatry and behavioural neuroscience at the University of
              Chicago, loneliness is the discrepancy between what you have and
              what you want from your relationships.
            </p>
            <p className="section-content">
              The interesting thing about loneliness is that you need not be
              alone to feel it. The sensation creeps up in several
              situations, including feeling misunderstood by your family or when
              you feel left out by your friends. It is certainly an
              uncomfortable feeling, making you more self-critical and doubtful,
              leading to a rather pessimistic worldview. Although you
              might feel helpless, with continued effort, it is certainly
              possible to overcome loneliness. Keep reading to learn
              how!
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Acknowledging The Feeling</h2>
            <p className="section-content">
              Loneliness is often associated with being a ‘loner, a loser or
              having no friends.’ Thus, it definitely feels scary,
              shameful and uncomfortable to admit that you’re experiencing
              loneliness. However, it is imperative to the process of
              alleviating the feeling. Suppressing feelings of
              loneliness will only perpetuate them further, so it is essential
              that you are honest with yourself about the experience.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Remind Yourself You’re Not Alone</h2>
            {/* <!-- image here --> */}
            <p className="section-content">
              Don’t roll your eyes just yet. Though seemingly
              redundant, it is comforting to think of everyone out there
              experiencing the same feeling as you, almost like an ethereal bond
              between you and other lonely people out there. It is
              also reassuring to hear it from people experiencing
              loneliness. The Lonely Hour, a podcast where people open
              up about their struggles with isolation, is definitely worth
              checking out.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">
              Consider The Connections You Already Have
            </h2>
            <p className="section-content">
              Sometimes the solution can be right before your eyes. If
              you think your friends do not seem to care about you, contemplate
              whether you might have overlooked certain actions which they
              performed to show you they were there for you. For
              example, a friend might not be vocal about their concern for you
              or talk to you every day, but always offer their support in terms
              of acts of service. This means although they might lack
              communication skills, they definitely do care for you, and you’re
              not quite as alone as you thought!
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Volunteer</h2>
            {/* <!-- image here --> */}
            <p className="section-content">
              Activities such as helping the elderly or a beach clean-up can be
              incredibly fulfilling and will aid in creating a sense of feeling
              needed. Besides this, you will get to spend time with
              other volunteers and the people you’re serving, which is a great
              opportunity for connection. This website contains
              information about the various places that require volunteers
              within the country.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Review Your Social Media Usage</h2>
            <p className="section-content">
              Although social media has done wonders to connect people across
              the globe, it can often cause you to neglect your real-life
              relationships and isolate yourself further. Moreover,
              with the illusion of perfection social media brings, it seems like
              everyone is always having a better time than you, be it work,
              vacationing or relationships. Hence, if your social
              media usage seems to be doing you more harm than good, consider
              drawing some boundaries with it.
            </p>
          </div>
          <div className="section">
            <p className="section-content">
              While using these strategies, it is important to keep in mind that
              overcoming loneliness is a continuous process which is certainly
              not linear. While there will be days where you will
              appreciate being alone, other days will not be as easy.
              It is important to remember to be gentle with yourself, taking it
              one day at a time. Although a challenge, the journey to
              blissful solitude is undeniably worth it.
            </p>
          </div>
        </summary>
      </div>

      <div className="post-bottom">
        <div className="likes">
          {isLiked ? (
            <button onClick={handleLikeClick}>
              <img src={likeButton} alt="heart-filled-button" width={30} />
            </button>
          ) : (
            <button onClick={handleLikeClick}>
              <img src={notLikedButton} alt="heart-empty-button" width={30} />
            </button>
          )}
        </div>
        <h2 style={{ fontWeight: 200 }}>More</h2>
        {articles.slice(randomNum, randomNum + 3).map((item, index) => {
          return (
            <BlogsListCard
              author={item.author}
              dateUploaded={item.dateUploaded}
              imageURL={item.imageURL}
              title={item.title}
              url_param={item.url_param}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}
