import React, { useEffect, useState } from "react";
import "./newfeaturedpage.css";
import f1 from "../../assets/hero-image/featured1.png";
import f2 from "../../assets/hero-image/featured2.png";
import questionimg from "../../assets/featured/question 1.png";
import f3 from "../../assets/featured/homeimg2.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { fireDB } from "../../utils/config/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
export const NewFeaturedPage = (props) => {

  const currentUser = useSelector((state) => state.user.currentUser);

  const [expertList, setExpertList] = useState([]);

  const navigate = useNavigate();


  useEffect(() => {

    fetchExpert();

  }, []);
  



  const fetchExpert = async () => {
    const q = query(
      collection(fireDB, "/userProfiles/types/professional"),
      where("isAdminExpert", "==", true)
    );
  
    const professionalSnapshot = await getDocs(q);
  
    const expertList = professionalSnapshot.docs
      .map((doc) => doc.data())
      .filter((expert) => expert.isAdminExpert); // Filter experts here
  
    setExpertList(expertList);
  };

  




  const viewExpert = () => {
    if (expertList.length > 0) {
      const username = expertList[0].username;
      const uid = expertList[0].uid;
      navigate(`/ExpertsProfile/${username}/${uid}`);
    } else {

      console.error("No experts found in expertList");
    }
  };

  const viewTherapy = ()=>{
    navigate("/therapy")
  }

 


  return (
    <div className="new-featured-page">
      <div className="new-featured-page-box reverse">
        <div className="new-featured-page-box2">
          <p className="featured-text1">Say hi to a happier you</p>
          <p className="featured-text2">
            Curomates allows you to find relevant mental health communities,
            have free private chats with empathetic listeners, find
            psychologists, try guided meditation, use diagnosis or monitoring
            tools, and much more.
          </p>
        </div>
        <div className="new-featured-page-box1">
          <img src={f3} height={100} width={100} />
        </div>
      </div>
      <div className="new-featured-page-box">
        <div className="new-featured-page-box1">
          <p className="featured-text1">Made for everyone </p>
          <p className="featured-text2">
            Built on principles of compassion and inclusivity, we offer
            personalized support tailored to your needs. Take control of your
            mental health with Curomates and discover a path to greater
            well-being.
          </p>
          <button onClick={viewTherapy}>Find a therapist NOW</button>
        </div>
        <div className="new-featured-page-box1">
          <img src={f1} height={100} width={100} />
        </div>
      </div>
      <div className="new-featured-page-mobile">
        <div className="new-featured-page-mobile-box1">
          <img className="mobile-img" src={f2} />
          <div className="new-featured-page-mobile-box1-textbox textbox2">
          Have free private chats with empathetic listeners, find new friends, try meditation sessions, and much more.
          </div>
        </div>
        <div className="new-featured-page-mobile-box1">
          <div className="new-featured-page-mobile-box1-textbox">
          Take control of your mental health with Curomates and discover a path to greater well-being.
          </div>
          <img className="mobile-img" src={f1} />
        </div>
      </div>
      <div className="new-featured-page-bottombox">
        <div className="bottombox-left">
          <img
            className="questionmark"
            src={questionimg}
            height={100}
            width={100}
          />
          <div className="left-bottombox-textbox">
            <p className="bottombox-text">Not sure exactly what you need?</p>
            <p className="bottombox-text2">
              Talk to one of our mental wellness consultants for free!
            </p>
            <button>Book a call for free</button>
          </div>
        </div>
        <div className="bottombox-right">
          <button onClick={viewExpert}>Book a call for free</button>
        </div>
      </div>
    </div>
  );
};
