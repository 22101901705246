import React from 'react'
import imageLeft from "../../assets/imageSections/img-1.png"
import imageCenter from "../../assets/imageSections/img-2.png"
import imageRight from "../../assets/imageSections/img-3.png"
import "./ImageBanners.css"

export default function ImageBanners() {
  return (
    <div className='bannersContainer'>
        
        <a href='/blogs/posts/a-road-to-be-taken-self-love-2-2'
        className="subContainer">
            <h1 className='headingTop'>Read our blogs</h1>
            <img src={"https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-12%2Fself-love-2.jpg?alt=media&token=20bd8247-b318-43fc-9d76-56dcf0d4c40e"} alt="banner1"  />
            <h1 className='headingBottom'>A Road to be Taken: Self-Love</h1>
        </a>
        <a href='/blogs/posts/5-signs-youre-feeling-burned-out'
        className="subContainer">
            <img src={"https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-13%2Fburned-out.jpg?alt=media&token=32207d53-819d-4750-91b9-8b9119f2ce3e"} alt="banner2"  />
            <h1 className='headingBottom'>5 Signs You're Feeling Burned Out</h1>
        </a>
        <a href='/blogs/posts/how-to-make-a-good-daily-routine'
        className="subContainer">
            <img src={"https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-15%2Fset-daily-routine.webp?alt=media&token=ace86f08-c01f-4897-846c-24bb8ea5d6ce"} alt="banner3" />
            
            <h1 className='headingBottom'>How to make a good daily routine</h1>
        </a>
        <a href='/blogs/posts/your-5-step-guide-to-overcoming-loneliness'
        className="subContainer">
            <img src={"https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-4%2Fovercoming-loneliness.jpg?alt=media&token=9501f68c-987b-45ed-bda3-850823bc2531"} alt="banner4" />
            
            <h1 className='headingBottom'>Your 5 Step Guide To Overcoming Loneliness</h1>
        </a>
       
       
    </div>
  )
}
