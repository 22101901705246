import React from "react";
import { useParams } from "react-router-dom";
import HowToManageAnxiety from "./posts/HowToManageAnxiety";
import WaysToSetHealthyBoundaries from "./posts/WaysToSetHealthyBoundaries";
import BoundariesWithPeople from "./posts/BoundariesWithPeople";
import Your5StepGuideToOvercomingLoneliness from "./posts/Your5StepGuideToOvercomingLoneliness";
import CopingWithRejection from "./posts/CopingWithRejection";
import WhatSuppressingYourEmotionsCanMeanForYou from "./posts/WhatSuppressingYourEmotionsCanMeanForYou";
import StrategiesToManageAnger from "./posts/StrategiesToManageAnger";
import ARoadToBeTakenSelfLove1 from './posts/ARoadToBeTakenSelfLove1';
import AStepByStepGuideToProcessingYourEmotions from './posts/AStepByStepGuideToProcessingYourEmotions';
import DealingWithGuilt from './posts/DealingWithGuilt';
import HowToBeAnEmpatheticListener from './posts/HowToBeAnEmpatheticListener';
import ARoadToBeTakenSelfLove2 from './posts/ARoadToBeTakenSelfLove2';
import SignsYoureFeelingBurnedOut from './posts/SignsYoureFeelingBurnedOut';
import WhatToDoTheNightBeforeAnExam from './posts/WhatToDoTheNightBeforeAnExam';
import HowToMakeAGoodDailyRoutine from './posts/HowToMakeAGoodDailyRoutine';

export default function BlogPost() {
  const { blogTitle } = useParams();
  switch (blogTitle) {
    case "how-to-manage-your-social-anxiety":
      return <HowToManageAnxiety url_param={blogTitle} />;
    case "5-ways-to-set-healthy-boundaries-with-social-media":
      return <WaysToSetHealthyBoundaries url_param={blogTitle} />;
    case "5-tips-for-setting-boundaries-with-people":
      return <BoundariesWithPeople url_param={blogTitle} />;
    case "your-5-step-guide-to-overcoming-loneliness":
      return <Your5StepGuideToOvercomingLoneliness url_param={blogTitle} />;
    case "coping-with-rejection":
      return <CopingWithRejection url_param={blogTitle} />;
    case "what-suppressing-your-emotions-can-mean-for-you":
      return <WhatSuppressingYourEmotionsCanMeanForYou url_param={blogTitle} />;
    case "5-strategies-to-manage-anger":
      return <StrategiesToManageAnger url_param={blogTitle} />;
    case "a-road-to-be-taken-self-love-1-2":
      return <ARoadToBeTakenSelfLove1 url_param={blogTitle} />;
    case "a-step-by-step-guide-to-processing-your-emotions":
      return <AStepByStepGuideToProcessingYourEmotions url_param={blogTitle} />;
    case "dealing-with-guilt":
      return <DealingWithGuilt url_param={blogTitle} />;
    case "how-to-be-an-empathetic-listener":
      return <HowToBeAnEmpatheticListener url_param={blogTitle} />;
    case "a-road-to-be-taken-self-love-2-2":
      return <ARoadToBeTakenSelfLove2 url_param={blogTitle} />;
    case "5-signs-youre-feeling-burned-out":
      return <SignsYoureFeelingBurnedOut url_param={blogTitle} />;
    case "what-to-do-the-night-before-an-exam":
      return <WhatToDoTheNightBeforeAnExam url_param={blogTitle} />;
    case "how-to-make-a-good-daily-routine":
      return <HowToMakeAGoodDailyRoutine url_param={blogTitle} />;

    default:
      break;
  }
}
