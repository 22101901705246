import React, { useEffect } from 'react'

export default function PaymentComplete() {

  window.postMessage(JSON.stringify({ paymentComplete: true }));

    useEffect(()=>{

      window.ReactNativeWebView.postMessage((JSON.stringify({ paymentComplete: true })));
      
    },[])
    
    const handleButtonClick = () =>{

     // alert("hi")

      //
      
    //  window.postmessage(JSON.stringify({ paymentComplete: true }));
    }

  return (
    <div>
      PaymentComplete
      <button onClick={handleButtonClick}>Click Me</button>
      </div>
  )
}
