import React, { useState } from "react";
import { getBlogByParam, getRandom } from "../../../utils/utils";
import { articles } from "../../../data/data";
import InfoBar from "./InfoBar";
import "./Post.css";
import BlogsListCard from "../BlogsListCard";
import likeButton from "../../../assets/blogs/heart-like-filled.png";
import notLikedButton from "../../../assets/blogs/heart-like-empty.png";

export default function ARoadToBeTakenSelfLove1({ url_param }) {
  const { title, imageURL, author, dateUploaded } = getBlogByParam(
    articles,
    url_param
  );
  const [isLiked, setIsLiked] = useState(false);
  const randomNum = getRandom(11);
  const handleLikeClick = () => setIsLiked((p) => !p);

  return (
    <div className="post">
      <div className="post-container">
        <h1 className="post-heading">{title}</h1>
        <InfoBar author={author} dateUploaded={dateUploaded} />
        <img src={`${imageURL}`} alt="blog-cover" />
        {/* MAIN CONTENT */}
        <summary className="content-container">
          <div className="section">
            <p className="section-content">
              You’ve probably heard this word millions of times, before and
              since the pandemic began, and it’s become one of those woke words
              that I see social media use: “You can’t love another person until
              you love yourself first.” And they are partially correct;
              ‘Self-Love’ is very important for everyone for a variety of
              reasons, which we will discuss.
            </p>
            <p className="section-content">
              It is outlined as “a state of appreciation for oneself that grows
              from actions that support our physical, psychological, and
              spiritual growth” (Khoshaba, 2012).
            </p>
            <p className="section-content">Too wordy right?</p>
            <p className="section-content">
              To begin with, self-love is not a scientific term, and its
              application is difficult in this capitalist and hustle-oriented
              culture. We are far more inclined to forgive others’ flaws than
              our own. So it is the ability to accept oneself mindfully, that
              is, without criticism or judgment at this very moment for the
              whole being that you are.
            </p>
            <p className="section-content">
              And, for all my fellow emotional over-extenders and compassionate
              peeps out there, no, self-love is not narcissism.
            </p>
            <p className="section-content">
              Self-love has a lot to do with one’s well-being; it could be
              something as simple as:
            </p>
            <ul className="section-content">
              <li>Putting yourself first</li>
              <li>Take the time out and talk to those you love</li>
              <li>Taking a break from self-criticism</li>
              <li>Taking a break from people who can be toxic to you</li>
              <li>Setting balanced personal boundaries</li>
              <li>Indulging in self-care.</li>
            </ul>
            <p className="section-content">
              For this certain article, let’s try to understand varied and
              related concepts.
            </p>
          </div>

          <div className="section">
            <h2 className="section-title">Self-love vs. Self-compassion</h2>
            <p className="section-content">
              Self-compassion and self-love are two related concepts but they
              are still distinct. Self-compassion is an appreciation for one’s
              self-worth, despite experiences that do not always go as planned.
            </p>
            <p className="section-content">
              It’s to turn the same amount of love, tolerance, acceptance and
              empathy inwards, that otherwise, you would show to others. It is
              forgiving oneself in the face of personal failings and choosing
              compassion at that moment (momentary in nature). Self-love, on the
              other hand, is more of a dynamically consistent construct (that
              is, consistently choosing to engage in self-love so that you
              progressively get better at it), it is a work-in-progress over
              time.
            </p>
            <p className="section-content">
              For example, tell yourself what you would tell a friend who was
              struggling with a new task at work: ‘Hey, you’re putting too much
              pressure on yourself for a task you’ve never done before, this is
              an opportunity to learn, don’t be disheartened. Let’s take a break
              and try it once more when feeling refreshed.’
            </p>
            <p className="section-content">
              I really do believe that if Maslow had witnessed the current
              absence of self-compassion and self-love, he would have added them
              to his hierarchy of needs.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Self-love vs. Selfishness</h2>
            <p className="section-content">
              People who frequently describe themselves as compassionate often
              struggle to internalize that level of compassion and self-love
              when it comes to themselves. Self-compassion and self-love are
              frequently confused with selfishness.
            </p>
            <p className="section-content">
              If you’ve read the book ‘The Fountainhead by Ayn Rand,’ you’ve
              probably come across the philosophical debate between selfishness
              and self-interest. I’d like to draw an association between
              self-love and self-compassion with self-interest and apply the
              same discussion here.
            </p>
            <p className="section-content">
              Self-interest is not selfishness; the former does not deprive
              caring for others, but the latter can be harmful to others.
            </p>
            <p className="section-content">
              It’s the same analogy your airline’s flight attendants use: help
              yourself before helping others. And this makes perfect sense;
              consider a therapist who is mentally exhausted but still tries to
              help their clients, how detrimental could that be, or a mother who
              is seriously ill and on bedrest; how could she possibly help
              someone else?
            </p>
          </div>

          <div className="section">
            <h2 className="section-title">Self-love vs. Self-confidence</h2>
            <p className="section-content">
              The English language can be confusing, but self-compassion,
              self-esteem, and self-confidence are distinct concepts that happen
              to fall under the umbrella of self-love.
            </p>
            <p className="section-content">
              While self-confidence makes you feel more convinced of your
              abilities, it can also induce you to vastly overstate those
              abilities. Self-compassion, as opposed to that, encourages you to
              admit your faults and restrictions, allowing you to look at
              yourself impartially and realistically. And, self-love above all
              just means the ability to accept oneself presently without any
              judgment, as a whole.
            </p>
          </div>

          <div className="section">
            <h2 className="section-title">Self-love vs. Self-esteem</h2>
            <p className="section-content">
              Another term that people may confuse with self-love is
              self-esteem. Self-esteem is defined as associating one’s identity
              with one’s accomplishments and strengths, whereas self-compassion
              is defined as accepting oneself despite failures. Those who have
              high self-esteem may not have high self-compassion. So, because of
              its stability, I believe it is safe to say that self-compassion
              trumps self-esteem.
            </p>
          </div>

          <div className="section">
            <h2 className="section-title">Self-love vs. Self-care</h2>
            <p className="section-content">
              The notion of self-love is frequently linked to self-care, which
              is one of the self-do methods. Self-care entails making time for
              activities that make you feel good, happy, and rested.
            </p>
            <p className="section-content">
              These activities are beneficial for your mental, physical, and
              emotional health. Self-care can clearly help with well-being, and
              I’m certain you’ve heard about it ever since the pandemic started.
              Self-care can include anything from riding your bike, making
              yourself a concoction of chai to taking warm baths, and everything
              in-between. There’s no right or wrong way to practice self-care;
              whatever makes you happy is the right way.
            </p>
          </div>

          <div className="section">
            <p className="section-content">
              With the alarming rates of mental exhaustion and mental health
              issues, pushing ‘the self’ six feet under did no good in the long
              run. Take the time, however much time you may require, to improve
              and make the necessary changes in your lifestyle. In the
              subsequent part (Part 2), I’ll go over some ways to cultivate a
              sense of self-love. Keep an eye out for Part 2 of the Self-Love: A
              Series.
            </p>
          </div>
        </summary>
      </div>

      <div className="post-bottom">
        <div className="likes">
          {isLiked ? (
            <button onClick={handleLikeClick}>
              <img src={likeButton} alt="heart-filled-button" width={30} />
            </button>
          ) : (
            <button onClick={handleLikeClick}>
              <img src={notLikedButton} alt="heart-empty-button" width={30} />
            </button>
          )}
        </div>
        <h2 style={{ fontWeight: 200 }}>More</h2>
        {articles.slice(randomNum, randomNum + 3).map((item, index) => {
          return (
            <BlogsListCard
              author={item.author}
              dateUploaded={item.dateUploaded}
              imageURL={item.imageURL}
              title={item.title}
              url_param={item.url_param}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}
