import React, { useState } from "react";
// import image from "./wave (2).svg";
import "./newhomepage.css";
import { HeroText, ImageComponent } from "../home/Hero";

const NewHeroPage = () => {
  return (
    <div className="newhome-box">
      <div className="home-box">
        <div className="home-box1">
          <HeroText/>
        </div>
        <div className="home-box1">
          <ImageComponent/>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 319">
        <path
          fill="#fff"
          fill-opacity="1"
          d="M0,200L60,190C120,180,240,160,360,170C480,180,600,200,720,210C840,220,960,220,1080,210C1200,200,1320,180,1380,170L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>
    </div>
  );
};

export default NewHeroPage;
