import logo1 from "../../assets/featured/f-logo1.png"
import logo2 from "../../assets/featured/f-logo2.png"
import logo3 from "../../assets/featured/f-logo3.png"
import logo4 from "../../assets/featured/f-logo4.png"
import logo5 from "../../assets/featured/f-logo5.png"
import logo6 from "../../assets/featured/f-logo6.png"
import logo7 from "../../assets/featured/f-logo7.png"
import logo8 from "../../assets/featured/f-logo8.png"
import "./Featured.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";


export default function Featured() {
  const [ref, inView] = useInView({triggerOnce:true, threshold:1});
  const featuredImagesVariant ={
    offScreen: {
      y: "100px",
      opacity: 0,
    },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  }

  const containerVariant = {
    offScreen: {},
    onScreen: {
        transition: {
            staggerChildren: 0.3
        }
    }
};

  return (
    <motion.div
      className="featured"
      ref={ref}
      initial="offScreen"
      animate={inView ? "onScreen" : "offScreen"}
    >
      <motion.h1 className="center" variants={featuredImagesVariant}>
        As featured on
      </motion.h1>
      <motion.div className="featured-icons" variants={containerVariant}>
        <motion.img
          src={logo1}
          alt="curomates-india"
          className="featured-india center"
          variants={featuredImagesVariant}
        />

        <motion.img
          src={logo2}
          alt="curomates-iit"
          className="featured-iit center"
          variants={featuredImagesVariant}
        />
        <motion.img
          src={logo3}
          alt="curomates-expo-dubai"
          className="featured-expo-dubai center"
          variants={featuredImagesVariant}
        />
        <motion.img
          src={logo4}
          alt="curomates-inc"
          className="featured-inc center"
          variants={featuredImagesVariant}
        />
        <motion.img
          src={logo5}
          alt="curomates-inc"
          className="featured-inc center"
          variants={featuredImagesVariant}
        />
        <motion.img
          src={logo6}
          alt="curomates-inc"
          className="featured-inc center"
          variants={featuredImagesVariant}
        />
        <motion.img
          src={logo7}
          alt="curomates-inc"
          className="featured-inc center"
          variants={featuredImagesVariant}
        />
        <motion.img
          src={logo8}
          alt="curomates-inc"
          className="featured-inc center"
          variants={featuredImagesVariant}
        />

      </motion.div>
    </motion.div>
  );
}
