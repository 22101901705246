import React, { useState } from "react";
import { getBlogByParam, getRandom } from "../../../utils/utils";
import { articles } from "../../../data/data";
import InfoBar from "./InfoBar";
import "./Post.css";
import BlogsListCard from "../BlogsListCard";
import likeButton from "../../../assets/blogs/heart-like-filled.png";
import notLikedButton from "../../../assets/blogs/heart-like-empty.png";

export default function HowToMakeAGoodDailyRoutine({ url_param }) {
  const { title, imageURL, author, dateUploaded } = getBlogByParam(
    articles,
    url_param
  );
  const [isLiked, setIsLiked] = useState(false);
  const randomNum = getRandom(11);
  const handleLikeClick = () => setIsLiked((p) => !p);

  return (
    <div className="post">
      <div className="post-container">
        <h1 className="post-heading">{title}</h1>
        <InfoBar author={author} dateUploaded={dateUploaded} />
        <img src={`${imageURL}`} alt="blog-cover" />
        {/* MAIN CONTENT */}
        <summary className="content-container">
          <div className="section">
            <p className="section-content">
              Modern lives now are all about the hustle and the grind. Every day
              sees a new challenge, a new possibility. When this hustle takes up
              every inch of our life, it can be difficult to find time to sit
              back and relax our mind.
            </p>
            <p className="section-content">Here is where a routine comes in.</p>
            <p className="section-content">
              A routine is made of a structure and sequence of habits that help
              us go through the day in an orderly manner. A lack of a routine
              might give room for spontaneity but eventually falls into disorder
              and only serves to stress us out. The presence of a routine has
              also been linked with lower levels of anxiety.
            </p>
            <p className="section-content">
              Making a routine is, no doubt, not easy. So, here are a few tips
              you can use in your daily life to bring in more order and peace of
              mind.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">30 Minutes Of 'Me Time'</h2>
            <p className="section-content">
              If, after you wake up, the first thing you do is reach for your
              phone, you're not alone. According to a survey from Reviews.org,
              71% of people check their phone within 10 minutes of waking up.
              However, this sets the tone for the rest of the day. You may never
              know what information you are met with, and you are not in a
              mindset to greet it with a proper attitude. Therefore, it is
              advised to set aside the first 30 minutes of your day for
              yourself.
            </p>
            <p className="section-content">
              This may include any activities that clear your mind like
              stretching, working out, meditating, or journaling. Making a list
              of all that you need to do that day and finishing your chores can
              also make your day more productive.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">A Good Breakfast</h2>
            <p className="section-content">
              Breakfast is the most important meal of the day for more reasons
              than one. As the name suggests, it is the meal that breaks your
              overnight fast and replenishes your energy and alertness. This is
              why a proper breakfast should be one of your utmost priorities.
              For the most nutritious breakfast, try to choose whole,
              unprocessed foods from each of the five food groups: fruits,
              vegetables, grains, protein foods, and dairy. Try to include
              proteins from foods like yogurts (look for varieties with less
              sugar added), eggs, nuts and seeds, or legumes.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Energy Breaks</h2>
            <p className="section-content">
              In a fast-paced and mission-driven world, breaks are often
              preached but not practiced well enough. For a healthy balance
              between your life and mental health, it is important to take a few
              breaks throughout the day for restoring your energy.
            </p>
            <p className="section-content">
              These breaks can be anywhere between an hour or half. Just a few
              moments where you are not thinking of your next goal, your
              upcoming exam or project, and instead are appreciating everything
              you currently have. This may include physical and social
              activities like going out with friends or it can be moments of
              solitude like reading a book or taking a nap.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Gratitude Journal</h2>
            <p className="section-content">
              In positive psychology research, gratitude is strongly and
              consistently associated with greater happiness. Gratitude helps us
              feel more positive emotions, relish good experiences, improve your
              health, deal with adversity, and build strong relationships.
              Maintaining a gratitude journal gives you space to recognize and
              acknowledge everything in your present life that you are thankful
              for.
            </p>
            <p className="section-content">
              Before ending the day, make sure to write down 2 things about
              today and 3 things in your life that you are grateful about. In
              the morning, reading your entry from the night before can help
              give a positive boost.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Stretch Your Body</h2>
            <p className="section-content">
              Throughout the day, the muscles in your body absorb tension. This
              tension, if not released, causes pain during the day and leads to
              fitful sleep. The best way to deal with this problem is by
              stretching your body before sleeping. It not only helps with
              muscle tension but also aids in improving blood flow and fixing
              posture, all ingredients for a perfect recipe of good sleep.
            </p>
            <p className="section-content">
              It is to be noted that maintaining your routine every day is not
              easy and you must never beat yourself up over not following
              through one thing and simply try to do that thing the next day.
              These are only habits in the making. Here's hoping that these tips
              help you kickstart your own daily routine for a great day!
            </p>
          </div>
        </summary>
      </div>

      <div className="post-bottom">
        <div className="likes">
          {isLiked ? (
            <button onClick={handleLikeClick}>
              <img src={likeButton} alt="heart-filled-button" width={30} />
            </button>
          ) : (
            <button onClick={handleLikeClick}>
              <img src={notLikedButton} alt="heart-empty-button" width={30} />
            </button>
          )}
        </div>
        <h2 style={{ fontWeight: 200 }}>More</h2>
        {articles.slice(randomNum, randomNum + 3).map((item, index) => {
          return (
            <BlogsListCard
              author={item.author}
              dateUploaded={item.dateUploaded}
              imageURL={item.imageURL}
              title={item.title}
              url_param={item.url_param}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}
