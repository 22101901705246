import anirudhImg from "../assets/about-us/about-us-anirudh.png";
import sanketImg from "../assets/about-us/about-us-sanket.png";
import prernaImg from "../assets/about-us/about-us-prerna.png";

export const dummyTeam = [
  {
    name: "Anirudh",
    designation: "Co-founder",
    description: "Handles product design and marketing at Curomates. Anirudh worked in management consulting after an MBA from the Indian Institute of Foreign Trade. He also was #3 at Point Network, a Web3 startup based in Dubai. When he's not working, he likes drawing and playing football. He also plays Valorant, but unfortunately cannot get past the Diamond rank.",
    image:anirudhImg
  },
  {
    name: "Sanket",
    designation: "Co-founder",
    description: "Sanket handles everything tech at Curomates. He started writing software for hedge funds straight out of college, has won the Smart India Hackathon(Asia's largest hackathon) twice and is a Microsoft AI for Earth grantee. He has also received recognition in national media for multiple social impact projects. He likes working out and going on long and sometimes, quite frankly, dangerous treks.",
    image:sanketImg
  },
  {
    name: "Prerna",
    designation: "Community Lead",
    description: "Prena is a psychologist and handles community management and outreach at Curomates. Prerna also is an excellent chef, and is the best cook at Curomates (although sadly, that is not saying much)",
    image:prernaImg
  }
];

export const categories = [
  {
    cat_id: "1",
    categoryName: "Socializing and Loneliness",
    articleIds: ["1","2","3","4"],
    url_param: "socializing-and-loneliness"
  },
  {
    cat_id: "2",
    categoryName: "Relationships",
    articleIds: ["5",],
    url_param: "relationships"
  },
  {
    cat_id: "3",
    categoryName: "Dealing with your Emotions",
    articleIds: ["6","7","8","9","10","11","12"],
    url_param: "dealing-with-your-emotions"
  },
  {
    cat_id: "4",
    categoryName: "Exams and Work Stress",
    articleIds: ["13","14",],
    url_param: "exams-and-work-stress"
  },
  {
    cat_id: "5",
    categoryName: "Daily Routines and Confidence",
    articleIds: ["1","3","5","8","10","15"],
    url_param: "daily-routines-and-confidence"
  },
  {
    cat_id: "7",
    categoryName: "All blogs",
    articleIds: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14","15"],
    url_param: "all-blogs"
  }
];


export const articles = [
  {
    aid: "1",
    title: "How To Manage Your Social Anxiety",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-1%2Fhow-to-manage-anxiety.webp?alt=media&token=5db91cc0-5012-4073-a4be-c4d73166a7e6",
    author: "Brishni Neogi",
    dateUploaded: "Aug 5, 2023",
    category: ["Socializing and Loneliness","Daily Routines and Confidence"],
    isFeatured: true,
    url_param: "how-to-manage-your-social-anxiety"
  },
  {
    aid: "2",
    title: "5 Ways To Set Healthy Boundaries With Social Media",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-2%2Fhealthy-boundaries.jpg?alt=media&token=63fe8f73-a4b4-4437-acd2-226a6402fd32",
    author: "Amaia Tahilramani",
    dateUploaded: "Aug 3, 2023",
    category: ["Socializing and Loneliness"],
    isFeatured: false,
    url_param: "5-ways-to-set-healthy-boundaries-with-social-media"
  },
  {
    aid: "3",
    title: "5 Tips for Setting Boundaries with People",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-3%2Fsetting-boundaries.jpg?alt=media&token=263479b0-8702-455b-be6e-bfa849bea0d8",
    author: "Meghana Injeti",
    dateUploaded: "Aug 1, 2023",
    category: ["Socializing and Loneliness","Daily Routines and Confidence"],
    isFeatured: false,
    url_param: "5-tips-for-setting-boundaries-with-people"
  },
  {
    aid: "4",
    title: "Your 5 Step Guide To Overcoming Loneliness",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-4%2Fovercoming-loneliness.jpg?alt=media&token=9501f68c-987b-45ed-bda3-850823bc2531",
    author: "Amaia Tahilramani",
    dateUploaded: "Aug 10, 2023",
    category: ["Socializing and Loneliness"],
    isFeatured: false,
    url_param: "your-5-step-guide-to-overcoming-loneliness"
  },
  {
    aid: "5",
    title: "Coping With Rejection",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-5%2Fcoping-with-rejection.jpg?alt=media&token=576f62b5-a0a2-419c-a4d5-ed458641f1f1",
    author: "Amaia Tahilramani",
    dateUploaded: "Aug 18, 2023",
    category: ["Relationships","Daily Routines and Confidence"],
    isFeatured: true,
    url_param: "coping-with-rejection"
  },
  {
    aid: "6",
    title: "What Suppressing Your Emotions Can Mean for You",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-6%2Fsupressing-your-emotions.webp?alt=media&token=a3ff891b-b66c-47ff-850a-cd6b82573cb9",
    author: "Gurupriya Sanjali",
    dateUploaded: "Aug 7, 2023",
    category: ["Dealing with your Emotions"],
    isFeatured: false,
    url_param: "what-suppressing-your-emotions-can-mean-for-you"
  },
  {
    aid: "7",
    title: "5 Strategies to Manage Anger",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-7%2Fmanage-anger.jpg?alt=media&token=a65aca60-671e-4205-b99a-9e94bdbbf062",
    author: "Meghana Injeti",
    dateUploaded: "Aug 8, 2023",
    category: ["Dealing with your Emotions"],
    isFeatured: false,
    url_param: "5-strategies-to-manage-anger"
  },
  {
    aid: "8",
    title: "A Road to be Taken: Self-Love (1/2)",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-8%2Fself-love-1.jpg?alt=media&token=a6170f1f-f61e-474c-a399-80f3dcf90a05",
    author: "Meghana Injeti",
    dateUploaded: "Aug 15, 2023",
    category: ["Dealing with your Emotions","Daily Routines and Confidence"],
    isFeatured: false,
    url_param: "a-road-to-be-taken-self-love-1-2"
  },
  {
    aid: "9",
    title: "A Step-by-Step Guide to Processing Your Emotions",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-9%2Fmanaging-emotions.jpg?alt=media&token=b1467d49-d751-4700-9896-4f95cafe56b2",
    author: "Gurupriya Sanjali",
    dateUploaded: "Aug 16, 2023",
    category: ["Dealing with your Emotions"],
    isFeatured: false,
    url_param: "a-step-by-step-guide-to-processing-your-emotions"
  },
  {
    aid: "10",
    title: "Dealing With Guilt",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-10%2Fdealing-with-guilt.webp?alt=media&token=8f64f06b-0d1c-42ea-b7b8-fd0e4dae64b9",
    author: "Brishni Neogi",
    dateUploaded: "Aug 20, 2023",
    category: ["Dealing with your Emotions","Daily Routines and Confidence"],
    isFeatured: false,
    url_param: "dealing-with-guilt"
  },
  {
    aid: "11",
    title: "How to be an Empathetic Listener",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-11%2Fhow-to-be-an-empathetic-listener.jpg?alt=media&token=43afe320-ff67-4a12-934f-671d66429660",
    author: "Gurupriya Sanjali",
    dateUploaded: "Aug 22, 2023",
    category: ["Dealing with your Emotions"],
    isFeatured: false,
    url_param: "how-to-be-an-empathetic-listener"
  },
  {
    aid: "12",
    title: "A Road to be Taken: Self-Love (2/2)",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-12%2Fself-love-2.jpg?alt=media&token=20bd8247-b318-43fc-9d76-56dcf0d4c40e",
    author: "Meghana Injeti",
    dateUploaded: "Aug 24, 2023",
    category: ["Dealing with your Emotions"],
    isFeatured: false,
    url_param: "a-road-to-be-taken-self-love-2-2"
  },
  {
    aid: "13",
    title: "5 Signs You're Feeling Burned Out",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-13%2Fburned-out.jpg?alt=media&token=32207d53-819d-4750-91b9-8b9119f2ce3e",
    author: "Gurupriya Sanjali",
    dateUploaded: "Jul 29, 2023",
    category: ["Exams and Work Stress"],
    isFeatured: false,
    url_param: "5-signs-youre-feeling-burned-out"
  },
  {
    aid: "14",
    title: "What To Do The Night Before An Exam",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-14%2Fnight-before-exam.jpg?alt=media&token=e3ff7a96-596a-4e4e-a9b8-74e5286eb986",
    author: "Amaia Tahilramani",
    dateUploaded: "Jul 25, 2023",
    category: ["Exams and Work Stress"],
    isFeatured: false,
    url_param: "what-to-do-the-night-before-an-exam"
  },
  {
    aid: "15",
    title: "How to make a good daily routine",
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/curomates-test.appspot.com/o/aid-15%2Fset-daily-routine.webp?alt=media&token=ace86f08-c01f-4897-846c-24bb8ea5d6ce",
    author: "Brishni Neogi",
    dateUploaded: "Aug 12, 2023",
    category: ["Daily Routines and Confidence"],
    isFeatured: false,
    url_param: "how-to-make-a-good-daily-routine"
  }
];
