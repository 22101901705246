import * as actionTypes from "../actions/types";

const initialUserState = {
  currentUser: null,
  memberProfile: null,
  listenerProfile: null,
  isUserLoading: true,
};

const user_reducer = (state = initialUserState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        currentUser: action.payload.currentUser,
        isUserLoading: false,
      };

    case actionTypes.SET_MEMBER_PROFILE:
      return {
        ...state,
        memberProfile: action.payload.memberProfile,
      };

    case actionTypes.SET_LISTENER_PROFILE:
      return {
        ...state,
        listenerProfile: action.payload.listenerProfile,
      };

    case actionTypes.CLEAR_USER:
      localStorage.clear(); // Clear localStorage

      localStorage.removeItem("persist:root"); // Remove the persisted root item

      return {
        currentUser: null,
        memberProfile: null,
        listenerProfile: null,
        isUserLoading: false,
      };

    default:
      return state;
  }
};

export default user_reducer;
