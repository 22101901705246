import React from 'react'
import "./Hero.css";
import { motion } from "framer-motion";

export default function HeroSubSectionCard(props) {

    const {cardDetails} = props

    const containerVariant = {
        offScreen: {},
        onScreen: {
          transition: {
            staggerChildren: 0.1,
          },
        },
      };

  return (
    <motion.div key={props.index} variants={containerVariant} style={{alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"column",backgroundColor:"#CFDFFF",margin:10,padding:10,borderRadius:20}}>
        <img
        style={{marginBottom:10}}
        src={cardDetails.image}
        width={70}
        />

        <div style={{textAlign:"center",fontWeight:"bold"}}>{cardDetails.text1}</div>

        <div style={{textAlign:"center",marginTop:10}}>{cardDetails.text2}</div>
        
        </motion.div>
  )
}
