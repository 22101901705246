import React, { useState } from "react";
import { getBlogByParam, getRandom } from "../../../utils/utils";
import { articles } from "../../../data/data";
import InfoBar from "./InfoBar";
import "./Post.css";
import BlogsListCard from "../BlogsListCard";
import likeButton from "../../../assets/blogs/heart-like-filled.png";
import notLikedButton from "../../../assets/blogs/heart-like-empty.png";

export default function BoundariesWithPeople({ url_param }) {
  const { title, imageURL, author, dateUploaded } = getBlogByParam(
    articles,
    url_param
  );
  const [isLiked, setIsLiked] = useState(false);
  const randomNum = getRandom(11);
  const handleLikeClick = () => setIsLiked((p) => !p);

  return (
    <div className="post">
      <div className="post-container">
        <h1 className="post-heading">{title}</h1>
        <InfoBar author={author} dateUploaded={dateUploaded} />
        <img src={`${imageURL}`} alt="blog-cover" />
        {/* MAIN CONTENT */}
        <summary className="content-container">
          <div className="section">
            <p className="section-content">
              You’ve said ‘yes’ to something you really didn’t want to say ‘yes’
              to, and now you’re feeling overwhelmed because taking back your
              word seems worse than selling your soul.
            </p>
            <p className="section-content">
              No, I’m not talking about a pact with the Illuminati, but rather
              about the need for personal boundaries such as physical, sexual,
              emotional, and time boundaries. The term “Personal
              Boundaries” has to do with establishing boundaries and rules for
              who one is as a person and how one chooses to interact with
              others.
            </p>
            <p className="section-content">
              To be honest, I’ve been in a pickle or two where I wasn’t able to
              draw the line when or where I should have, but it’s never too late
              to learn and apply. Sure, along the way you’ll often have
              people call you selfish, rude, or blunt for looking after
              yourself, but real friends will understand, and at the end of the
              day, having your space will be worth it.
            </p>
            <p className="section-content">
              Just so we can get started with drawing the lines, here are a few
              things to keep in mind.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">
              Tune in and clarify your needs and wants
            </h2>
            <p className="section-content">
              Our body’s basic survival mechanism sends us signals when we are
              threatened or overwhelmed, and trust me that’s a gift from an
              evolutionary perspective. Have you ever noticed your heart
              racing or your breathing becoming shorter? You probably
              break out in a cold sweat or feel a heaviness in your chest, don’t
              you? Or do you have an uncontrollable urge to fidget or
              shake your legs? Its important to ascertain why that’s
              happening.
            </p>
            <p className="section-content">
              Following these signals from your body, if one is willing to
              identify the problems and explore the solutions, then that’s the
              path to the light at the end of the tunnel.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Start with Self-respect</h2>
            <p className="section-content">
              Be it overextending yourself more than bargained for, or being as
              rigid as a board, both aren’t ideal positions to be in.
              You might ask, “where does one start when drawing the
              boundaries?” Well, you could start with the tiny voice
              inside your head called ‘self-respect’. The goal is to
              strike a healthy balance of respect for yourself and others, that
              is being considerate of others' boundaries without dismissing
              yours. So, ultimately, it comes down to where you stand
              on the ‘balance’ continuum.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Communicate with Intent</h2>
            {/* <!-- image here --> */}
            <p className="section-content">
              ‘Communication’ may appear to be a simple word, but it carries far
              more weight than its appearance suggests. It stands to
              reason that anyone approached with an air of superiority and
              demand will be unable to persuade or negotiate an agreement;
              unless it’s a military school you referring to.
            </p>
            <p className="section-content">
              Mentioned below are a few psychologist-referred communication
              tricks one could utilise;
            </p>
            <ul className="section-content">
              <li>
                Learning to make requests over demands, when attempting to
                persuade someone to modify, change, or take action, requesting
                them to do so rather than demanding seems more consequential.
              </li>
              <li>
                The magic of “It Would Mean a Lot” statements can be used when
                you believe in sticking to your boundaries and the importance of
                them, without arousing a defensive stance from the opposite
                side. For example; “It would mean a lot if you could
                text me when you get home”.
              </li>
              <li>
                Another communication technique is “I-Statements,” which are
                sets of statements most likely beginning with “I feel”,
                “when/that”, and “and I’d like” that make a conversation more
                effective without being harsh or disrespectful.
              </li>
            </ul>
            <p className="section-content">Examples:</p>
            <ul className="section-content">
              <li>
                I feel anxious when you don’t drop me a text after reaching home
                safely. I’d like it if you could just drop a
                text next time, is that something you are willing to do?
              </li>
              <li>
                When you didn’t text me back, I felt anxious. So could
                you just drop a text next time?
              </li>
              <li>
                After reaching home, I’d like it if you could drop me a text.
              </li>
            </ul>
          </div>
          <div className="section">
            <h2 className="section-title">Make the Assertion</h2>
            <p className="section-content">
              People may have strong feelings about your decision to take a step
              back and give yourself some space, but compromising on your need
              to say “no” probably isn’t the best solution; the trick is to be
              clear and to do it respectfully. Being assertive does
              not imply being loud, brash, or even rude; rather, it means being
              honest, open, and speaking one’s mind without trying to please
              others. Assertiveness is simply the importance given to
              one’s limits in a relationship when the parties involved are
              willing to understand and respect the boundaries that others
              set.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Consistency is Key</h2>
            {/* <!-- image here --> */}
            <p className="section-content">
              From a behavioural standpoint, when one is trying to reinforce a
              specific behaviour in a person, it needs to happen on a scheduled
              time or ratio basis. To put it simply, consistency
              matters. It is critical to be consistent and firm in
              enforcing your boundaries, especially when dealing with those who
              try to resist them, take it passively, or don’t get the
              hint. Consistency reinforces the seriousness and
              significance of the boundaries to yourself and to other people,
              therefore consistency earns consequences.
            </p>
          </div>
          <div className="section">
              The idea of boundaries has ever so been taken for granted and only
              now is it being given any precedence on the line to standard well
              being; be it your boss pushing you to work past 6 pm because it
              lightens their burden or your parents crossing your emotional
              threshold while using blackmail as a means to get to
              you. It takes skill and paramount tolerance to block it
              off all the time, but it does require some form of intervention in
              the long run.
            <p className="section-content">
              When and if you decide you’ve had enough of tolerance and consider
              taking action, remember that self-care isn’t
              selfishness. Setting boundaries can be difficult and
              requires practice and some soul searching, but recollect that you
              owe it to yourself.
            </p>
          </div>
        </summary>
      </div>

      <div className="post-bottom">
        <div className="likes">
          {isLiked ? (
            <button onClick={handleLikeClick}>
              <img src={likeButton} alt="heart-filled-button" width={30} />
            </button>
          ) : (
            <button onClick={handleLikeClick}>
              <img src={notLikedButton} alt="heart-empty-button" width={30} />
            </button>
          )}
        </div>
        <h2 style={{ fontWeight: 200 }}>More</h2>
        {articles.slice(randomNum, randomNum + 3).map((item, index) => {
          return (
            <BlogsListCard
              author={item.author}
              dateUploaded={item.dateUploaded}
              imageURL={item.imageURL}
              title={item.title}
              url_param={item.url_param}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}
