import React, { useState } from "react";
import { getBlogByParam, getRandom } from "../../../utils/utils";
import { articles } from "../../../data/data";
import InfoBar from "./InfoBar";
import "./Post.css";
import BlogsListCard from "../BlogsListCard";
import likeButton from "../../../assets/blogs/heart-like-filled.png";
import notLikedButton from "../../../assets/blogs/heart-like-empty.png";

export default function WhatToDoTheNightBeforeAnExam({ url_param }) {
  const { title, imageURL, author, dateUploaded } = getBlogByParam(
    articles,
    url_param
  );
  const [isLiked, setIsLiked] = useState(false);
  const randomNum = getRandom(11);
  const handleLikeClick = () => setIsLiked((p) => !p);

  return (
    <div className="post">
      <div className="post-container">
        <h1 className="post-heading">{title}</h1>
        <InfoBar author={author} dateUploaded={dateUploaded} />
        <img src={`${imageURL}`} alt="blog-cover" />
        {/* MAIN CONTENT */}
        <summary className="content-container">
          <div className="section">
            <p className="section-content">
              Whether you have memorised every syllable of your textbook cover
              to cover, or just about peeling off the packaging, no one is safe
              from the exam eve jitters. With entrance exams like CUET and NEET
              looming over us, students across India can definitely feel the
              tension building. Here are a few strategies to cope with the
              pre-test stress.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Get a good night's sleep</h2>
            <p className="section-content">
              Just a few hours till the paper and the study material seems
              endless. It is that very moment where pulling off that all-nighter
              seems increasingly inevitable. However, this might prove to work
              against you, greatly reducing your focus while writing your exam
              the next morning. Getting at least 7–8 hours of sleep before an
              exam greatly helps memory retention and retrieval, ensuring great
              results.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Resist the temptation to cram</h2>
            <p className="section-content">
              Sticking to what you know right before an exam will definitely
              yield the best results. Avoid trying to learn brand new concepts
              and study material you are not familiar with. Instead, focus on
              the topics you have understood well and are confident with, and
              read through less familiar content if time permits. It might even
              be helpful to check out study channels on YouTube such as Khan
              Academy or Crash Course, which is an excellent revision
              alternative.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Eat the right food</h2>
            <p className="section-content">
              Although often overlooked, nutrition plays a major role in
              producing excellent exam results. The coffee enthusiasts will be
              happy to know that coffee is a great way to improve concentration
              and boost memory, provided it is not consumed before sleeping.
              Other foods that greatly help memory, focus, and concentration are
              nuts, broccoli, oranges, eggs, and even dark chocolate. You should
              also make it a point to treat yourself to your favorite foods to
              help cope with the exam stress.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Try to stay calm</h2>
            <p className="section-content">
              You're probably rolling your eyes at this one. Nonetheless, there
              are several relaxation techniques you could employ to reduce
              stress, including deep breathing, visualizing a positive outcome,
              and listening to your favorite music. If the nerves seem like too
              much to handle, be sure to talk to a friend about how you feel. It
              always helps knowing you are not alone in your worries. Apart from
              this, you could always vent to a listener on the Curomates app.
              Sharing your feelings with an understanding listener is certain to
              offer you some respite.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Stay clear of distractions</h2>
            <p className="section-content">
              The college WhatsApp group, which once had interaction levels
              similar to a deserted island, is now buzzing with queries about
              concepts you have never encountered and images of parts of your
              textbook you have never seen. Such discussions are bound to make
              you doubt your preparedness for the exam and cause you stress. It
              would thus be advisable to avoid all social media unless
              absolutely necessary. You also might want to pause that show on
              Netflix you have been binge-watching religiously till after your
              papers.
            </p>
          </div>
          <div className="section">
            <p className="section-content">
              Keeping these tips in mind, it is important to remember that not
              everyone is cut out from the same cloth. Exam stress affects
              everyone differently. Nonetheless, it is important for students to
              be kind to themselves and remember that grades are not everything.
              Try to stay positive and do not put too much pressure on yourself!
            </p>
          </div>
        </summary>
      </div>

      <div className="post-bottom">
        <div className="likes">
          {isLiked ? (
            <button onClick={handleLikeClick}>
              <img src={likeButton} alt="heart-filled-button" width={30} />
            </button>
          ) : (
            <button onClick={handleLikeClick}>
              <img src={notLikedButton} alt="heart-empty-button" width={30} />
            </button>
          )}
        </div>
        <h2 style={{ fontWeight: 200 }}>More</h2>
        {articles.slice(randomNum, randomNum + 3).map((item, index) => {
          return (
            <BlogsListCard
              author={item.author}
              dateUploaded={item.dateUploaded}
              imageURL={item.imageURL}
              title={item.title}
              url_param={item.url_param}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}
