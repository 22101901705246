import React from "react";
import { Link, useMatch } from "react-router-dom";
import "./BlogsPage.css";
import CategoriesSection from "../../components/blogs/CategoriesSection";
import RecentBlogs from "../../components/blogs/RecentBlogs";
import { getFeatured } from "../../utils/utils";
import { articles } from "../../data/data";

//  ------------------------------FEATURED BLOGS SECTION------------------------------
const FeaturedBlogs = () => {
  const data = getFeatured(articles)  
  //   BLOGS FEATURED CARD
  return (

    <div className="blogs-featured">
      {data.map((item, index) => {
        return (
          <Link key={index} to={`/blogs/posts/${item.url_param}`} style={{textDecoration:'none', color:'white'}}>
          <div key={index} className="blogs-featured-card" style={{ backgroundImage: `url(${item.imageURL})`, backgroundSize:'cover' }}>
            <div className="blogs-featured-card-tag">Featured Blog Post</div>
            <section>
              <h4>
                {item.author} • {item.dateUploaded}
              </h4>
              <h1>{item.title}</h1>
            </section>
          </div>
          </Link>
        );
      })}
    </div>
  );
};

export default function BlogsPage() {
  let match = useMatch("*");
  let path = match.pathname;
  return (
    <>
      <div className="blogs-container">
        <div className="blogs">
          <FeaturedBlogs />
          <CategoriesSection />
          <RecentBlogs />
        </div>
      </div>
    </>
  );
}
