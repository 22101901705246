import React from "react";
import BlogsListCard from "./BlogsListCard";
import { getRecentBlogs } from "../../utils/utils";
import { articles } from "../../data/data";

export default function RecentBlogs() {
  const data = getRecentBlogs(articles)

  return (
    <div className="blogs-recent">

      <h1>Recent Blogs</h1>

      <section className="blogs-recent-list">
        {data.map((item, index) => (
            
          <BlogsListCard
            title={item.title}
            imageURL={item.imageURL}
            key={index}
            author={item.author}
            dateUploaded={item.dateUploaded}
            url_param={item.url_param}
          />
        ))}
      </section>
    </div>
  );
}
