import React from 'react'
import "./RefundPolicy.css"

export default function RefundPolicy() {
  return (
    <div className="refund-policy-container">
      <h1>Curomates Refund And Cancellation Policy</h1>
      <p className="update-date">Updated 21 Dec, 2023</p>

      <p>
        <h2 className="para-heading">Parties</h2>
        <p>
          The terms &#39;You&#39; or &#39;Your&#39; refer to You as a Registered
          User and the terms &#39;Curomates&#39;,&#39;We&#39;, &#39;Us&#39;,
          &#39;Company&#39;, and &#39;Our&#39; refer to Curomates Private
          Limited OPC. Registered User means any person who has completed the
          registration process for receiving services offered by Curomates by
          payment of the requisite fees.
        </p>
      </p>

      <p>
        <h2 className="para-heading">Background</h2>
        <p>
          When you book a session with a psychologist on Curomates or make a
          request to talk to a volunteer listener or verified listener, you
          confirm that you are not at risk of harming yourself or others.
          Curomates makes best efforts to provide psychologist’s services in a
          professional manner while keeping in mind the needs and interests of
          service recipients. Moreover, Curomates acts as a third- party
          provider that connects independent healthcare professionals, volunteer
          listeners/verified listeners and other entities on its platform (all
          of whom are unaffiliated with Curomates). Curomates is not responsible
          for the content of the chats, video and audio calls or other
          communication between entities on its platform.
        </p>
      </p>

      <p>
        <h2 className="para-heading">Subscription or Bookings</h2>
        <p>
          <p>
            • When you purchase a Subscription or a Product (each, a
            “Transaction”), we may ask you to supply additional information
            relevant to your Transaction, such as your credit card number, the
            expiration date of your credit card and your address(es) for billing
            and delivery (such information, “Payment Information”). You
            represent and warrant that you have the legal right to use all
            payment method(s) represented by any such Payment Information. The
            amounts due and payable by you for a Transaction through the
            Services will be presented to you before you place your order.  If
            you choose to initiate a Transaction via the Services, you authorize
            us to provide your Payment Information to third party service
            providers so we can complete your Transaction and agree (a) to pay
            the applicable fees and any taxes; (b) that Curomates may charge
            your credit card or third party payment processing account,
            including, but not limited to, your account with the app store or
            distribution platform (like the Apple App Store, Google Play or the
            Amazon Appstore) where the App is made available (each, an “App
            Provider”), for verification, pre-authorization and payment
            purposes; and (c) to bear any additional charges that your App
            Provider, bank or other financial service provider may levy on you
            as well as any taxes or fees that may apply to your order. Your
            order is not binding on Curomates until accepted and confirmed by
            Curomates. All payments made are non-refundable and non-transferable
            except as expressly provided in these Terms.
          </p>
          <p>
            • If you have any concerns or objections regarding charges, you
            agree to raise them with us first and you agree not to cancel or
            reject any credit card or third-party payment processing charges
            unless you have made a reasonable attempt at resolving the matter
            directly with Curomates.
          </p>
          <p>
            • If your account is banned, you will not receive a refund for any
            purchases you have already made via your account.If your account is
            banned, you will not receive a refund for any purchases you have
            already made via your account. If you purchase a subscription plan
            or Joybits on our platform, you cannot get a refund. In certain
            cases, you might be able to upgrade your plan.
          </p>
          <p>
          From your listener wallet, you can withdraw Joybits in multiples of 100. Once you make a request for
          a withdrawal, we may take up to 15 business days to process the transaction. Please note that at the
          moment, we can only allow for withdrawals via UPI.
          </p>
          <p>
            • If you purchase a plan and your request is accepted by a
            volunteer/verified listener, Curomates will not be liable if the
            chat or video/audio call is not to your standards, and/or if the
            listener does not reply/answer the call. Curomates will try its best
            to screen good listeners for the platform, but as these listeners
            are not affiliated with Curomates in any way, we cannot take
            responsibility for the content of any chats or calls between users
            on the platform. Joybits are charged from a user only once both
            parties are in the audio/video call. Curomates does not refund purchased Joybits.
          </p>
          <p>
            • Curomates reserves the right to permanently ban accounts at any
            time, in the interest of our community.
          </p>
          <p>
            • If your account is banned from Curomates for any reason, you are
            not entitled to a refund for any purchases made.
          </p>
          <p>
            • If you purchase a subscription to talk to volunteer or verified
            listeners, Curomates is not responsible for ensuring that listeners
            reply, or for the conversation in any way. Curomates is simply a
            platform that connects entities, and is not affiliated with any
            volunteer listeners, users, or psychologists/professionals.
          </p>

          <p>
            Curomates may run promotional offers on our platforms or marketing
            material from time to time at its sole discretion. Curomates shall
            also have the sole discretion to modify the terms of such offers or
            cancel such offers. You shall not be entitled to utilize such offers
            as a matter of right.
          </p>

          <p>
            The conditions under which Curomates may provide refunds and the
            terms and conditions associated with it are specified in this refund
            policy{" "}
            <strong>
              {" "}
              &quot; Curomates Refund And Cancellation Policy&quot;.
            </strong>
            Curomates will not under any other circumstances entertain any
            requests for refund.
          </p>
          <p></p>

          <p>
            <h2 className="para-heading">Applicability</h2>
            <p>
              You will be entitled to invoke this Refund Policy only as long as
              You are a Registered User. Your right to claim refund may arise in
              the event of the following types of defects or deficiencies on Our
              part: a. Accounting error e.g. your account was charged for more
              than actual number of availed sessions; b. Psychologist does not
              show up on time: For psychologist bookings, if the psychologist
              does not show up to the session at the appointed time or within 5
              minutes of the allotted starting time, we may offer a refund to
              you. If there is any dispute as to the existence of a defect or
              deficiency as mentioned above, the decision of Curomates shall be
              final. Any issue other than those specifically mentioned above,
              shall not be covered under this Refund Policy. If any deficiency
              in Service as reported by You is curable, Curomates may instead of
              refunding the money for services, at its discretion choose to
              re-perform the services.
            </p>
          </p>

          <h2 className="para-heading">Cut-Off Date</h2>
          <p>
            All claims for refund must be made by You within a period of (7)
            days from the date of purchase of our listed session packages.
            Beyond such a period, unfortunately we can&#39;t offer you a refund.
          </p>

          <h2 className="para-heading">Refunds Process</h2>
          <p>
            You can submit a request for refund by mailing us at
            support@curomates.com with your user ID and your grievance. In case
            your grievance is arising out of any quality issues - we will try
            our best to give you a corrective solution. For ease of processing
            &amp; documentation of this process we will keep the refund request
            through the email provided above only. Once the process is
            completed, we will communicate to You the extent of refund and a
            tentative date by which you can claim it. We try to respond to any
            request for refund within fifteen (15) working days of receipt of
            request.
          </p>
          <p>
            • All applicable payments should be made prior to availing Curomates
            services.
          </p>
          <p>
            • The user's session booking(s) will not be confirmed till the
            payment is received by Curomates.
          </p>
          <p>
            • If you are accessing our services through a third-party or your
            organisation, beyond any sessions that might be offered to you as
            part of Curomates' association with your organisation, the user's
            session booking(s) will not be confirmed till the payment is
            received by Curomates.
          </p>
          <p>• All purchases expire one year from the date of purchase.</p>
          <p></p>
          <p>Fee Information for psychologist sessions</p>
          <p>
            • For individual users, the fee per session is dependent on the
            selected psychological wellness expert.
          </p>
          <p>
            • For users who are accessing sessions through their organisation,
            beyond their included sessions, the fee per session is dependent on
            the selected psychological wellness expert and specific arrangements
            with their organisation.
          </p>
          <p>
            • Refunds will not be applicable on used sessions irrespective of
            the package purchased, and whether you have attended the session(s)
            or not.
          </p>
          <p>
            • A user cannot cancel or reschedule a session(s) when there are
            less than 24 hours left before the session is scheduled to start.
            Moreover, a user can only cancel or reschedule a session once. If
            you (the user) choose to cancel the session in time, you will
            receive a full refund.
          </p>
          <p>
            • No refund will be applicable on packages purchased at discounted
            prices redeemed through promotional codes or any special benefits &
            session credits which Curomates provides from time to time. If you
            have already attended a session in a package, you will not receive a
            refund for that session.
          </p>
          <p>
            • Other services on our platform, including self-help packages,
            happiness programs etc have a NO refund policy.
          </p>
          <p>
            • Non availability of a counsellor/psychologist will not account for
            requests for refund as we operate on a first come, first serve
            appointment basis.
          </p>
          <p>
            • If a counsellor/psychologist has to reschedule the session, the
            counsellor/psychologist will suggest a new time slot. If You cannot
            attend the session at the newly suggested time, you may ask the
            counsellor/psychologist to cancel the session instead (within 12
            hours of the psychologist suggesting the new time slot), in which
            case you shall receive a full refund.
          </p>
        </p>
        <h2 className="para-heading">
          Late or missing refunds (if applicable)
        </h2>
        <p>
          Generally, we take about 15 working days to process a refund; from the
          date a refund request is approved by Us. Delay in crediting a refund
          may be due to various reasons such as time taken by the banking
          process for reflecting the refund in your account. In case You have
          any doubts or need any information, please write back to us at
          support@curomates.com.
        </p>
        <h2 className="para-heading">
          Changes to Price Terms for Subscriptions
        </h2>
        <p>
          Curomates reserves the right to change its pricing terms for
          Subscriptions or counselling sessions at any time and make changes to
          its Refund Policy at any point of time. Curomates can choose whether
          to notify you in advance of such changes becoming effective. Changes
          to the pricing terms will not apply retroactively and will only apply
          for Subscription renewals after such changed pricing terms have been
          communicated to you. If you do not agree with the changes to
          Curomates’ pricing terms then you may choose not to renew your
          Subscription.
        </p>
      </p>

      <section className="grievance">
        <h2 className="para-heading">Grievance</h2>
        <p>
          If you have any grievance relating to the refund, please write to{" "}
          <a href="mailto:support@curomates.com">support@curomates.com</a>.
        </p>
      </section>
    </div>
  );
} 
