import React from "react";
import "./Hero.css";
import playStoreIcon from "../../assets/play_store_icon.png";
import appStoreIcon from "../../assets/app_store_icon.png";
import heroImage from "../../assets/hero-image/hero-upadted-img.png";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ToastContainer, toast } from "react-toastify";
import heroImagemobile from "../../assets/hero-image/hero--image.png";
import newheroimg from "../../assets/hero-image/new-hero.png"
export const HeroText = () => {
  const containerVariant = {
    offScreen: {},
    onScreen: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const textVariant = {
    offScreen: {
      y: "150px",
      opacity: 0,
    },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
  };

  const notify = () => {
    toast.info("Coming soon!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <motion.div
      className="hero-text"
      initial="offScreen"
      animate="onScreen"
      variants={containerVariant}
    >
      {/* First Headings */}
      <motion.h1 className="hero-text--heading" variants={textVariant}>
        Need someone to talk to?
      </motion.h1>
      <motion.h1 className="hero-text--heading" variants={textVariant}>
        Connect with real people, 24x7
      </motion.h1>

      {/* Description */}
      <motion.h3 className="hero-text--description" variants={textVariant}>
        Curomates allows you to find relevant mental health communities, have
        free private chats with empathetic listeners, find meditation sessions,
        and much more.
      </motion.h3>

      {/* Store Buttons */}

      {/* Button Wrapper */}
      <motion.div
        className="hero-button--wrapper hero-for-mobile"
        variants={textVariant}
      >
        <p>Get the app now!</p>
        <div className="button-box-wrapper">
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.curomates.mobile"
            rel="noopener noreferrer"
          >
            <button className="hero-playStore--button">
              Play Store{" "}
              <img
                src={playStoreIcon}
                alt="curomates-play-store-icon"
                className="hero-playStore--logo"
              />{" "}
            </button>
          </a>
          {/* <a href="/"> */}
          <button className="hero-appStore--button" onClick={notify}>
            App Store{" "}
            <img
              src={appStoreIcon}
              alt="curomates-app-store-icon"
              className="hero-appStore--logo"
            />{" "}
          </button>
          {/* </a> */}
        </div>
      </motion.div>
    </motion.div>
  );
};

export const ImageComponent = () => {
  return (
    <div className="home-hero-image">
      <LazyLoadImage
        src={newheroimg}
        height={100}
        width={100}
        alt="curomates-hero"
        className="home-hero-image--main"
        loading="lazy"
      />
      <LazyLoadImage
        src={newheroimg}
        height={100}
        width={100}
        alt="curomates-hero"
        className="home-hero-image--main-mobile"
        loading="lazy"
      />
    </div>
  );
};

export default function Hero() {
  return (
    <div className="hero-container">
      <div className="hero">
        <HeroText />
        <ImageComponent />
      </div>
    </div>
  );
}
