import React, { useState } from "react";
import { getBlogByParam, getRandom } from "../../../utils/utils";
import { articles } from "../../../data/data";
import InfoBar from "./InfoBar";
import "./Post.css";
import BlogsListCard from "../BlogsListCard";
import likeButton from "../../../assets/blogs/heart-like-filled.png";
import notLikedButton from "../../../assets/blogs/heart-like-empty.png";

export default function WhatSuppressingYourEmotionsCanMeanForYou({
  url_param,
}) {
  const { title, imageURL, author, dateUploaded } = getBlogByParam(
    articles,
    url_param
  );
  const [isLiked, setIsLiked] = useState(false);
  const randomNum = getRandom(11);
  const handleLikeClick = () => setIsLiked((p) => !p);

  return (
    <div className="post">
      <div className="post-container">
        <h1 className="post-heading">{title}</h1>
        <InfoBar author={author} dateUploaded={dateUploaded} />
        <img src={`${imageURL}`} alt="blog-cover" />
        {/* MAIN CONTENT */}
        <summary className="content-container">
          <div className="section">
            <h1 className="section-title">
              What Suppressing Your Emotions Can Mean for You
            </h1>
            <p className="section-content">
              By the end of this article, if it strikes you that your emotions
              have not really been managed optimally all along, just know that
              you’re not alone. Here’s why.
            </p>
            {/* <!-- image here --> */}
            <p className="section-content">
              Many of us grow up in sociocultural environments that value
              hustling, moving forward, and prioritising financial and
              occupational growth. We come to believe that a comfortable life
              with a dream job and a breath-taking mansion is the ultimate,
              ever-promising aspiration to seek. While chasing this dream and
              grappling with life’s ups and downs, a whole range of emotions
              crop up that start to feel like hurdles. In your journey to
              fulfilment, you wonder how you found yourself on an obstacle
              course of emotions that seem to slow you down. You think, maybe it
              is easier to set them aside to deal with later on so that you can
              focus on your immediate goals.
            </p>
            <p className="section-content">
              Most of us might be familiar with some version of this kind of
              life. Our tendency to view emotions, specifically “negative”
              emotions like anger, sadness, fear, and envy, as disruptions to
              normalcy that hinder how well we can work through them, and along
              with them. But don’t worry — this hardly ever comes naturally.
              When you are used to having your tears immediately wiped away and
              your outbursts of anger reprimanded and dismissed, it isn’t
              realistic to expect effortless emotion management. When these
              “negative” emotions don’t feel welcome, it seems safe to lock them
              away in a box buried deep inside and march ahead.
            </p>
            <p className="section-content">
              And then what happens to them? Where do they go?
            </p>
            <p className="section-content">
              It turns out, our stifled emotions still find ways to gain
              expression. A series of psychological and physical effects may
              ensue as a consequence of our suppressed emotions.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Psychological Effects</h2>
            <ul className="section-list">
              <li>
                <strong>Judging others or oneself for displaying emotions:</strong> Since you are
                habituated to your emotions being received in an unwelcome
                manner, a belief is instilled in you that expressions of
                undesirable emotions and vulnerability imply some degree of
                mental weakness. Not only is this increasingly harmful to your
                self-esteem and ability to conquer emotional adversity, it bears
                the risk of invalidating others’ emotions; making them feel
                unsafe and unheard.
              </li>
              <li>
                <strong>Believing that if you don’t suppress your emotions, they will
                take over your life:</strong> This belief may arise from months or years
                of accumulated emotions that couldn’t be expressed and now seem
                like they will consume you if you let go of the reins. Remind
                yourself that if you work on regulating your emotions, they can
                help guide the course of your life rather than throw it off
                course.
              </li>
              <li>
                <strong>Denying that you are feeling emotions that need to be dealt
                with:</strong> In order to avoid the disruption that it causes to your
                life, you refuse to acknowledge that you might need to pause and
                reflect on the new, overwhelming emotions in your life, or seek
                help to work through them.
              </li>
              <li>
                <strong>Wanting to withdraw from people and from draining activities:</strong> As
                a consequence of having suppressed emotions, your ability to
                extend yourself emotionally and socially gets hampered. You may
                find it difficult to be around people for too long or partake in
                activities that require considerable effort. Everything feels
                overwhelming and too much to deal with.
              </li>
              <li>
                <strong>Compromising on expressing your needs:</strong> When there is a conflict
                of interest, you would rather go with the flow and choose to not
                express your needs and wants as a result of being habituated to
                not revealing your emotions, and in order for the resultant
                conflict to not bring out emotions that both parties will have
                to deal with.
              </li>
              <li>
                <strong>Wanting to be around other people and the inability to be alone</strong>:
                Being around other people can often serve as a pleasant
                distraction that takes your attention away from yourself. When
                alone, you may be pushed to confront your emotions that you wish
                to avoid dealing with.
              </li>
              <li>
                <strong>(Trigger Warning: Self-Harm) Contemplating self-harm:</strong> By not
                taking and managing your emotions at face value, some people
                feel that a way to gain a sense of control over their emotions
                is by resorting to harming themselves with activities that
                endanger their mental and physical well-being. To some, this
                brings temporary relief, but is never a sustainable option and
                can aggravate existing emotional problems.
              </li>
            </ul>
          </div>
          <div className="section">
            <h2 className="section-title">Physical Effects</h2>
            <p className="section-content">
              While there is no way to ascertain that suppressing your emotions
              will definitely cause these issues, studies have found links
              between poorly managed emotional states and the following
              physiological illnesses.
            </p>
            <p className="section-content">Suppressed emotions may:</p>
            <ul className="section-list">
              <li>
                Factor in to cause depression, anxiety and increased stress
                levels
              </li>
              <li>Cause fatigue or sleep disturbances</li>
              <li>Hamper immune response</li>
              <li>Increase blood pressure and heart rate</li>
              <li>Cause changes in appetite and digestive problems</li>
              <li>Increase risk of diabetes and heart disease</li>
              <li>
                Cause chronic illness in response to childhood trauma (as found
                by a study conducted by Healthline).
              </li>
            </ul>
            <p className="section-content">
              Since pushing one’s emotions aside and moving forward has for
              years been lauded more than pausing to reflect on and deal with
              them, suppressed emotions may feel too normal to provoke the need
              for change. Taking the time to process those suppressed emotions
              will cause discomfort and significant personal upheaval, but is
              doubtlessly worth it. Stay tuned for Part 2 of “Managing Emotions:
              a Series” which covers how to process your emotions.
            </p>
          </div>
        </summary>
      </div>

      <div className="post-bottom">
        <div className="likes">
          {isLiked ? (
            <button onClick={handleLikeClick}>
              <img src={likeButton} alt="heart-filled-button" width={30} />
            </button>
          ) : (
            <button onClick={handleLikeClick}>
              <img src={notLikedButton} alt="heart-empty-button" width={30} />
            </button>
          )}
        </div>
        <h2 style={{ fontWeight: 200 }}>More</h2>
        {articles.slice(randomNum, randomNum + 3).map((item, index) => {
          return (
            <BlogsListCard
              author={item.author}
              dateUploaded={item.dateUploaded}
              imageURL={item.imageURL}
              title={item.title}
              url_param={item.url_param}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}
