import React, { useState } from "react";
import { getBlogByParam, getRandom } from "../../../utils/utils";
import { articles } from "../../../data/data";
import InfoBar from "./InfoBar";
import "./Post.css";
import BlogsListCard from "../BlogsListCard";
import likeButton from "../../../assets/blogs/heart-like-filled.png";
import notLikedButton from "../../../assets/blogs/heart-like-empty.png";

export default function SignsYoureFeelingBurnedOut({ url_param }) {
  const { title, imageURL, author, dateUploaded } = getBlogByParam(
    articles,
    url_param
  );
  const [isLiked, setIsLiked] = useState(false);
  const randomNum = getRandom(11);
  const handleLikeClick = () => setIsLiked((p) => !p);

  return (
    <div className="post">
      <div className="post-container">
        <h1 className="post-heading">{title}</h1>
        <InfoBar author={author} dateUploaded={dateUploaded} />
        <img src={`${imageURL}`} alt="blog-cover" />
        {/* MAIN CONTENT */}
        <summary className="content-container">
          <div className="section">
            <p className="section-content">Dreading work tomorrow?</p>
            <p className="section-content">
              Losing interest in workplace responsibilities?
            </p>
            <p className="section-content">
              Feeling exhausted or stuck in a loop?
            </p>
            <p className="section-content">You've come to the right place.</p>
            <p className="section-content">
              As worded by the ICD-11, a burnout is an occupational phenomenon
              that results from inadequately managed "chronic workplace stress"
              (WHO).
            </p>
            <p className="section-content">
              This may look like extreme physical and psychological exhaustion
              at the end of each work day, a sense of lacking motivation and
              feeling dissatisfied with the quality of your work, and being
              detached from and often irritable with your co-workers or client
              base.
            </p>
            <p className="section-content">
              Each individual responds to workplace stressors differently, but
              largely, a combination of the following factors is responsible for
              what causes a burnout: highly demanding tasks, unsatisfactory work
              culture, lacking agency in making work-related choices, poor
              work-life balance, and overexertion during work. Here are 5 signs
              you've been feeling burned out.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Feeling exhausted and fatigued</h2>
            <p className="section-content">
              This feeling is persistent; it starts in the morning, when instead
              of feeling energetic and anticipative of the day ahead of you, you
              feel bleak and unmotivated. Often, you require a mental nudge to
              drag yourself out of bed and make it to work. At night, a tiring
              day at work reinforces your lack of motivation and makes you dread
              the beckoning morning when the cycle begins all over again.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">
              Feeling indifferent towards responsibilities
            </h2>
            <p className="section-content">
              In other words, a burnout may cause you to feel apathetic towards
              your role and your ability to communicate effectively with your
              co-workers. Since the initial drive to give your best at work may
              have run out, the prospect of fulfilling your responsibilities and
              efficiently completing tasks starts to feel less invigorating and
              more taxing. Resultantly, you simply find it hard to care as much.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Hampered professional efficacy</h2>
            <p className="section-content">
              This happens often as a consequence of the first two factors. When
              work starts to feel too demanding, you lose interest in and don't
              get pleasure out of the responsibilities that you typically
              enjoyed shouldering. You may also find it hard to maintain a
              prolonged attention span or focus on tasks that require creative
              or critical engagement. Ultimately, this may show in your final
              performance; you find it difficult to live up to your potential.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">
              Experiencing sleep disturbances and changes in appetite
            </h2>
            <p className="section-content">
              A study conducted by Pagnin and Quieroz found that emotional and
              psychological stress have an effect on sleep physiology and impair
              REM sleep, which is known to have restorative properties for the
              body. Similarly, the same stress is responsible for putting your
              body in a persistent fight-or-flight mode, thus suppressing
              digestive processes and producing a feeling of fullness much
              sooner than normal. Both responses aren't healthy in the long term
              and need to be addressed promptly.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">
              Experiencing feelings of self-doubt and loneliness
            </h2>
            <p className="section-content">
              Repeatedly having trouble concentrating, lacking motivation,
              having difficulty contributing innovatively, and feeling tired
              easily can often lead to a debilitating sense of self-doubt.
              Constantly feeling like you are underperforming and not doing
              enough hampers self-confidence, which in turn affects your ability
              to work efficiently. Additionally, burning out produces a sense of
              feeling overwhelmed, which may ultimately leave you too drained to
              engage socially or maintain meaningful relationships with loved
              ones, thus creating space for increased loneliness.
            </p>
            <p className="section-content">
              While this list is comprehensive of the most common symptoms of a
              burnout, some less known but prevalent ones include weight gain,
              headaches and muscle aches, stomach issues, hampered immunity, and
              excessive reliance on food or substances as a way to cope.
              However, since most of these factors, in isolation, could be
              caused by other underlying problems, their occurrence may point at
              an impending burnout when appearing in combination with or as a
              result of the more common burnout symptoms.
            </p>
            <p className="section-content">
              Burnouts don't usually sort themselves out and require immediate
              action before they do further damage to your physical and mental
              health. The first step towards recovery requires some
              introspection to identify which aspect of your life may be causing
              you to burn out. This is crucial because burnouts can go beyond
              occupational stress and may source from emotional overexertion in
              other personal dimensions of life too. Having done so, it helps to
              recognize any immediate changes you can make to bring you relief
              in the present. For instance, "Am I taking on too many tasks
              today?" or "Do I have to finish this assignment by tonight?"
              Rescheduling some commitments may just be a temporary solution,
              but the subsequent time to rest will allow you to reorient
              yourself and see things in a different light. Consider your
              options based on the root of your burnout; can you speak to a
              trustworthy friend? Or would it help to have a counselor listen to
              you? Would your boss be open to hearing you out? Now, it is just a
              matter of understanding your needs, drawing boundaries to
              prioritize your peace and energy, and being kind to yourself and
              your immediate needs. Nothing helps more than regaining agency in
              your own life, even if it is as simple as pacing out your tasks
              and allowing yourself to procrastinate. Remind yourself that even
              small progress is progress.
            </p>
          </div>
        </summary>
      </div>

      <div className="post-bottom">
        <div className="likes">
          {isLiked ? (
            <button onClick={handleLikeClick}>
              <img src={likeButton} alt="heart-filled-button" width={30} />
            </button>
          ) : (
            <button onClick={handleLikeClick}>
              <img src={notLikedButton} alt="heart-empty-button" width={30} />
            </button>
          )}
        </div>
        <h2 style={{ fontWeight: 200 }}>More</h2>
        {articles.slice(randomNum, randomNum + 3).map((item, index) => {
          return (
            <BlogsListCard
              author={item.author}
              dateUploaded={item.dateUploaded}
              imageURL={item.imageURL}
              title={item.title}
              url_param={item.url_param}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}
