import React, { useState } from 'react'
import "./ContactUs.css"
import { Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap'
import { toast } from 'react-toastify';

export default function ContactUs() {

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    workEmail: '',
    phoneNumber: '',
    companyName: '',
    numberOfEmployees: '0',
  });

  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: '', // Clear the error when the user starts typing
    });
  };

  const validateForm = () => {
    const errors = {};
  
    // Validate First Name
    if (!formData.firstName.trim()) {
      errors.firstName = 'First Name is required';
    }
  
    // Validate Work Email
    if (!formData.workEmail.trim()) {
      errors.workEmail = 'Work Email is required';
    } else if (!/^\S+@\S+\.\S+$/.test(formData.workEmail)) {
      errors.workEmail = 'Invalid email format';
    }
  
    // Validate Phone Number (if provided)
    if (formData.phoneNumber && !/^[0-9]+$/.test(formData.phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number';
    }
  
    // Validate Company Name
    if (!formData.companyName.trim()) {
      errors.companyName = 'Company Name is required';
    }
  
    // Validate Number of Employees
    if (formData.numberOfEmployees === '0') {
      errors.numberOfEmployees = 'Please select the number of employees';
    }
  
    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      // Form is valid, make API call

      
        toast.success("Thanks, we will reach out to you soon!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });

      try {
        const response = await fetch('https://asia-south1-curomates-prod.cloudfunctions.net/api/sendCorporateEnquiryMail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        // Handle the response accordingly
        if (response.ok) {
          setFormData({
            firstName: '',
            lastName: '',
            workEmail: '',
            phoneNumber: '',
            companyName: '',
            numberOfEmployees: '0',
          })

        } else {
        }
      } catch (error) {

      }
    }
  };


  return (
    <div className='contact-us-container' id='contact-us'>
        
        <div className='contact-us-heading'>Boost your team's health and happiness now!</div>
        
        

        <div className='contact-us-form'>

          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="form-floating mb-3">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  placeholder="Enter your first name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </div>
              {formErrors.firstName && (
                <div className="error-message">
                  {formErrors.firstName}
                </div>
              )}
            </div>
            <div className="col-12 col-lg-6">
              <div className="form-floating mb-3">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  placeholder="Enter your last name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </div>
              {/* Display validation error for last name */}
              {formErrors.lastName && (
                <div className="error-message">
                  {formErrors.lastName}
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="form-floating mb-3">
                <label htmlFor="workEmail">Work Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="workEmail"
                  name="workEmail"
                  placeholder="Enter your work email"
                  value={formData.workEmail}
                  onChange={handleInputChange}
                />
              </div>
              {formErrors.workEmail && (
                <div className="error-message">
                  {formErrors.workEmail}
                </div>
              )}
            </div>
            <div className="col-12 col-lg-6">
              <div className="form-floating mb-3">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input
                  type="tel"
                  className="form-control"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Enter your phone number"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
              {formErrors.phoneNumber && (
                <div className="error-message">
                  {formErrors.phoneNumber}
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="form-floating mb-3">
                <label htmlFor="companyName">Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="companyName"
                  name="companyName"
                  placeholder="Enter your company name"
                  value={formData.companyName}
                  onChange={handleInputChange}
                />
              </div>
              {formErrors.companyName && (
                <div className="error-message">
                  {formErrors.companyName}
                </div>
              )}
            </div>
            <div className="col-12 col-lg-6">
              <div className="form-floating mb-3">
                <label htmlFor="numberOfEmployees">No. of employees</label>
                <select
                  className="form-control"
                  id="numberOfEmployees"
                  name="numberOfEmployees"
                  aria-label="Number of employees"
                  value={formData.numberOfEmployees}
                  onChange={handleInputChange}
                >
                  <option value={"0"} defaultValue>Select the number of employees</option>
                  <option value="0-50">less than 50</option>
                  <option value="50-100">50-100</option>
                  <option value="100-500">100-500</option>
                  <option value="500-1000">500-1000</option>
                  <option value="1000+">more than 1000</option>
                </select>
              </div>
              {formErrors.numberOfEmployees && (
                <div className="error-message">
                  {formErrors.numberOfEmployees}
                </div>
              )}
            </div>
          </div>

          {/* {Object.values(formErrors).map((error, index) => (
        <div key={index} className="error-message">
          {error}
        </div>
      ))} */}

      {/* Button to submit the form */}
      <div className="row align-items-center justify-content-center mt-4">
        <button className="submit-cta" onClick={handleSubmit}>
          <span className="submit-cta-text">Submit</span>
        </button>
      </div>

        </div>

        

    </div>
  )
}
