import React from 'react'
import Hero from '../components/corporate/Hero'
import ProcessTimeline from '../components/corporate/ProcessTimeline'
import EmployeeImpact from '../components/corporate/EmployeeImpact'
import GetInTouch from '../components/corporate/GetInTouch'
import Testimonials from '../components/corporate/Testimonials'
import ContactUs from '../components/corporate/ContactUs'

function CorporateOfferingsPage() {
  return (
    <div className="home">
      <Hero/>
      <ProcessTimeline />
      <EmployeeImpact />
      <GetInTouch />
      <Testimonials />
      <ContactUs />
    </div>
  )
}

export default CorporateOfferingsPage