import React, { useState } from "react";
import { getBlogByParam, getRandom } from "../../../utils/utils";
import { articles } from "../../../data/data";
import InfoBar from "./InfoBar";
import "./Post.css";
import BlogsListCard from "../BlogsListCard";
import likeButton from "../../../assets/blogs/heart-like-filled.png";
import notLikedButton from "../../../assets/blogs/heart-like-empty.png";

export default function WaysToSetHealthyBoundaries({ url_param }) {
  const { title, imageURL, author, dateUploaded } = getBlogByParam(
    articles,
    url_param
  );
  const [isLiked, setIsLiked] = useState(false);
  const randomNum = getRandom(11);
  const handleLikeClick = () => setIsLiked((p) => !p);

  return (
    <div className="post">
      <div className="post-container">
        <h1 className="post-heading">{title}</h1>
        <InfoBar author={author} dateUploaded={dateUploaded} />
        <img src={`${imageURL}`} alt="blog-cover" />
        <summary className="content-container">
          <div className="section">
            <p className="section-content">
              With 4.65 billion users across the globe, it is no secret that
              social media accounts form a huge part of our lives. Applications
              like Facebook and Instagram have done wonders in organizing
              events, starting important conversations and connecting people
              across the globe with just the click of a button. Their seamless
              dissipation of information gives us incredible access, right from
              current affairs and sports events, to particulars regarding who is
              vacationing in Goa and recent break ups.
            </p>
            <p className="section-content">
              Although this has several benefits, the constant access to content
              can often be overwhelming, and prove detrimental to our self-image
              and mental wellness. It has a tendency to create an illusion of
              unrealistic standards and a reality that seems too good to be
              true.The constant scrolling through perfect selfies, exuberant
              events and stunning travel destinations definitely makes people
              feel bad about the dullness of their own lives, and ultimately
              about themselves too. Moreover, social media is highly addictive,
              and was found to be more addictive than even cigarettes and
              alcohol, according to a study by Chicago university.This means
              that social media takes up so much of our time, that we have none
              left for our real life relationships. Thus, it is imperative that
              each one of us is mindful whilst on these networks and are
              conscious of how it affects us. Listed below are a few measures we
              can take to get the best out of our social media experience.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Knowing when you have had enough</h2>
            <p className="section-content">
              A simple way to avoid being submerged in the endless sea of posts
              and stories is by enforcing time limits for yourself. This can
              easily be done through settings in your smartphone itself, or
              downloading a social media tracking app like Moment or Stay Free.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Controlling your feed</h2>
            <p className="section-content">
              Rather than scrolling mindlessly through your timeline, try to be
              conscious of what you are looking at, and how it is impacting your
              emotions. If you find yourself feeling upset or angry by certain
              posts and accounts, do not hesitate to unfollow or even block
              them.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Try not to compare yourself to others</h2>
            <p className="section-content">
              Remind yourself that almost everything you see on social media was
              carefully chosen, tweaked and filtered to be fit for public
              consumption. Real life, by contrast, is full of mood swings, bad
              hair days and monotonous work. That being said, it is also filled
              with real people, random acts of kindness and uncontrollable
              laughter which social media is yet to replicate.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Take a day off (line)</h2>
            <p className="section-content">
              Rather than spending another weekend in bed scrolling through
              Instagram, try learning that skill that you have always wanted to
              pick up but never seem to have the time for, attend a workshop,
              hobby class, or a music event.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">
              Prioritize your real life interactions
            </h2>
            <p className="section-content">
              Try to refrain from using social media around friends, family or
              at social events. Resist the urge to check your socials, even
              though the dinner conversation is dull or you are bored of hearing
              about your sister’s school drama. The more you start valuing your
              interactions offline, the less susceptible you will be to the
              glitz and glamour that social media brings.
            </p>
          </div>
        </summary>
      </div>
      <div className="post-bottom">
        <div className="likes">
          {isLiked ? (
            <button onClick={handleLikeClick}>
              <img src={likeButton} alt="heart-filled-button" width={30} />
            </button>
          ) : (
            <button onClick={handleLikeClick}>
              <img src={notLikedButton} alt="heart-empty-button" width={30} />
            </button>
          )}
        </div>
        <h2 style={{ fontWeight: 200 }}>More</h2>
        {articles.slice(randomNum, randomNum + 3).map((item, index) => {
          return (
            <BlogsListCard
              author={item.author}
              dateUploaded={item.dateUploaded}
              imageURL={item.imageURL}
              title={item.title}
              url_param={item.url_param}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}
