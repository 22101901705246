import React, { useState } from 'react'
import "./NewsletterComponent.css"

import { toast } from 'react-toastify';

export default function NewsletterComponent() {

  const [emailAddress, setemailAddress] = useState("")
  const [errorMessage, seterrorMessage] = useState(null)

  const handleSubscribe = async () => {
    
      // Form is valid, make API call

      if (!emailAddress.trim()) {
        seterrorMessage('Email is required');
        return
      } else if (!/^\S+@\S+\.\S+$/.test(emailAddress)) {
        seterrorMessage('Invalid email format');
        return
      }

      
        toast.success("Subscribed to the newsletter!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });

          console.log(emailAddress)

      try {
        const response = await fetch('https://asia-south1-curomates-prod.cloudfunctions.net/api/sendNewsletterSubscriptionAlert', {
          method: 'POST',
          body: emailAddress,
        });

        // Handle the response accordingly
        if (response.ok) {
          setemailAddress("")

        } else {

        }
      } catch (error) {
      }

  };

  const handleEmailChange = (e) => {

    seterrorMessage(null)
    setemailAddress(e.target.value)

  }

  return (
    <div className='newsletter-component'>
      <input
        value={emailAddress}
        onChange={handleEmailChange}
        type="email"
        placeholder="email@example.com"
        className='newsletter-component-input'
      />
      <button onClick={handleSubscribe}>Subscribe</button>
      {errorMessage && (
                <div style={{color: "#C70039",
                margin: "1rem"}}>
                  {errorMessage}
                </div>
              )}
    </div>
  );
}
