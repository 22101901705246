import React from "react";
import instagramLogo from "../../assets/quick_links/instagram.png";
import twitterLogo from "../../assets/quick_links/twitter.png";
import linkedinLogo from "../../assets/quick_links/linkedin.png";
import mediumLogo from "../../assets/quick_links/medium.png";
import facebookLogo from "../../assets/quick_links/facebook.png";
import email from "../../assets/quick_links/email.png";
import "./QuickLinks.css";

export default function QuickLinks() {

  const cardVariants = {
    offScreen: {
      y: "100px",
      opacity: 0,
    },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
    };

  const containerVariant = {
    offScreen: {},
    onScreen: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };



  return (
    <div className="quickLink-container"  >
      
      <div className="quickLinks"  initial="offScreen" whileInView={"onScreen"} viewport={{ once: true, amount: 0.9 }} variants={containerVariant} >
        <h1>Quick Links</h1>
        <div>
          <a href="https://www.instagram.com/curomates_health/">
            <img src={instagramLogo} alt="instagram" variants={cardVariants} />
          </a>
          <a href="https://twitter.com/Curomates">
            <img src={twitterLogo} alt="twitter" variants={cardVariants} />
          </a>
          <a href="https://www.linkedin.com/company/curomates/">
            <img src={linkedinLogo} alt="linkedin" variants={cardVariants}/>
          </a>
          <a href="https://medium.com/@curomates">
            <img src={mediumLogo} alt="medium" variants={cardVariants}/>
          </a>
          <a href="https://www.facebook.com/Curomates/">
            <img src={facebookLogo} alt="facebook" variants={cardVariants}/>
          </a>
          <a href="mailto:support@curomates.com">
            <img src={email} alt="email" variants={cardVariants}/>
          </a>
        </div>
        </div>
    </div>
  );
}
