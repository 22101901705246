import React, { useState } from "react";
import { getBlogByParam, getRandom } from "../../../utils/utils";
import { articles } from "../../../data/data";
import InfoBar from "./InfoBar";
import "./Post.css";
import BlogsListCard from "../BlogsListCard";
import likeButton from "../../../assets/blogs/heart-like-filled.png";
import notLikedButton from "../../../assets/blogs/heart-like-empty.png";

export default function HowToBeAnEmpatheticListener({ url_param }) {
  const { title, imageURL, author, dateUploaded } = getBlogByParam(
    articles,
    url_param
  );
  const [isLiked, setIsLiked] = useState(false);
  const randomNum = getRandom(11);
  const handleLikeClick = () => setIsLiked((p) => !p);

  return (
    <div className="post">
      <div className="post-container">
        <h1 className="post-heading">{title}</h1>
        <InfoBar author={author} dateUploaded={dateUploaded} />
        <img src={`${imageURL}`} alt="blog-cover" />
        {/* MAIN CONTENT */}
        <summary className="content-container">
          <div className="section">
            <p className="section-content">
              Ever been told to put yourself in someone else’s shoes? You might
              recall wondering how to possibly do that without having
              experienced the same situation as them. Here’s a guide on how to
              be compassionate and understanding of someone’s experience
              regardless of personal familiarity with their emotional needs.
            </p>
            <p className="section-content">
              Empathy is the ability to understand someone’s perspectives and
              feelings with such depth that you can imagine having the same
              experience yourself. You don’t just listen to them, sympathize
              with their conflicts, and provide them with cursory advice. You
              form an emotional connection with their experience such that more
              than just knowing about it, you understand how it feels while
              feeling it along with them. Of course, this won’t necessarily mean
              that you go through an identical emotional journey with them.
              Empathy allows you to respond with deep emotional insight into
              someone’s distress by helping you form inferences drawn out of
              your own engagement with their emotions.
            </p>
            <p className="section-content">
              Why is empathy necessary? It enables you to comprehensively
              understand the other person’s emotional needs and perspectives and
              thus devise the most apt response. When people express
              vulnerability, they can rely on an empathetic listener to
              acknowledge their experience most reassuringly. Expressing empathy
              can give people in need a sense of belongingness; there lies
              special comfort in knowing that you are not alone in your
              troubles. It also has ample benefits for the listener; it allows
              for the formation of deeper, more healthy relationships, reduces
              negative and uninformed perspectives, and enables improved skills
              in collaboration and teamwork.
            </p>
            <p className="section-content">
              While empathy might come across as a skill that only warrants
              circumstantial practice, over time, it helps cultivate an
              empathetic outlook; a lens through which you begin to understand
              the world with a little more kindness and sensitivity. Read on to
              find out how to be an empathetic listener for someone in need of a
              helping hand.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Challenge your preconceptions</h2>
            <p className="section-content">
              Let’s face it — all of us have, at some point, trivialized the
              gravity of someone’s worries. This may be as simple as saying “why
              does this even bother them so much?” or “this isn’t a big deal” or
              “why aren’t they over this yet?”. In that moment, since we bear
              the privilege of speaking from the vantage point of a third party
              disconnected from the emotional hurdle, it’s quite easy to
              perceive it as unchallenging. However, when you truly place
              yourself in the other person’s headspace and anticipate potential
              aggravators of the situation, you will find yourself being kinder
              and more understanding of their reasons. For instance, counter
              your preconceptions by asking, “what if they’re going through some
              problems at home?” or “what if this is something that triggers
              them or makes them feel insecure?”.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Listen more, speak less</h2>
            <p className="section-content">
              It is easy to get carried away and focus on expressing your
              thoughts while participating in a conversation that you have so
              much to say about. But remind yourself that you are working on
              creating a safe space for someone that needs to be heard, and
              commit to the role of a listener. More than just keeping quiet and
              hearing what they have to say, this entails listening while
              maintaining body language that conveys to them that you are there
              for them.
            </p>
            <p className="section-content">
              For starters, give them your undivided attention; prioritize the
              time you committed to them, set your phone aside, and try to
              postpone other plans that may interfere with this time. Avoid
              interrupting them when they are opening up to you; sometimes, we
              tend to pick up on small, irrelevant details that make us curious
              and ask more about those. Doing so will make the person feel like
              you are more interested in hearing about trivial things that are
              significant to you; more so than their worries. Give them the
              space to rant if they need to. Often people have a lot of
              overwhelming, bottled up emotions that have not been given space
              to come forth. If they happen to trickle out in conversation with
              you, try not to stop that flow. However, if you don’t have the
              space to listen, it’s always okay to draw that boundary.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Show them your care and concern</h2>
            <p className="section-content">
              When someone you know is being vulnerable with you, it is of equal
              importance the manner in which you receive their worries and
              respond to them. In other words, how you choose to listen and make
              sense of their problems is as crucial as how you verbally
              acknowledge them. While listening to them, it’s important to
              sometimes see things beyond face value. When someone chooses not
              to confront their problem, consider that more than avoiding
              dealing with their troubles, there might just be a deeper obstacle
              that prevents them from doing so. Having considered this, now
              acknowledge and validate their choices when relevant. In doing so,
              they will feel heard.
            </p>
            <p className="section-content">
              While responding, ask and ponder over what kind of emotional
              support they might expect when in a vulnerable space. Questions
              like “is there anything I can do for you?”, “would you like to
              talk about this?” or even just “I’m here for you” do the trick.
              Sometimes, people prefer reassurance over logical advice, so tune
              into those cues when you’re listening.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Ask questions</h2>
            <p className="section-content">
              Be curious, but in a manner that expresses openness to learn and
              encourages the other person to comfortably open up. If someone
              expresses the willingness to confide in you, asking questions can
              help them streamline their train of thought and show them that you
              care to listen to their worries. It’s also crucial to look out for
              cues that they would rather not share; avoiding the subject and
              visible discomfort can tell you that they prefer to keep to
              themselves. In such cases, give them their space and allow them to
              approach you if they need to. While asking questions, carefully
              mull over what the person said to you and ask meaningful
              questions; for instance, asking “would you like to tell me more
              about why that bothers you?” instead of saying “don’t worry, it
              will pass”. This will show them that you care and that it is safe
              to be vulnerable with you.
            </p>
          </div>
          <div className="section">
            <p className="section-content">
              While these 4 pointers are apt ways to create a welcoming space
              for someone in need of a listener, most others will come up as the
              conversation progresses, and within its specific context. Remember
              that this list could never be conclusive because each individual’s
              needs differ when they are expressing vulnerability. It’s always
              okay to tune into and make clarifications about these needs in
              order to be there for them as best as you can.
            </p>
          </div>
        </summary>
      </div>

      <div className="post-bottom">
        <div className="likes">
          {isLiked ? (
            <button onClick={handleLikeClick}>
              <img src={likeButton} alt="heart-filled-button" width={30} />
            </button>
          ) : (
            <button onClick={handleLikeClick}>
              <img src={notLikedButton} alt="heart-empty-button" width={30} />
            </button>
          )}
        </div>
        <h2 style={{ fontWeight: 200 }}>More</h2>
        {articles.slice(randomNum, randomNum + 3).map((item, index) => {
          return (
            <BlogsListCard
              author={item.author}
              dateUploaded={item.dateUploaded}
              imageURL={item.imageURL}
              title={item.title}
              url_param={item.url_param}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}
