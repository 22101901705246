import { useState } from "react";
import "./Header.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/logo_footer.png";
import menuLogo from "../../assets/menu-logo.png";

export const DownloadButton = () => (
  <button className="download-button">Download App</button>
);

export default function Header() {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <header className="header">

      {/* menu toggle */}
      <button
        onClick={() => setIsNavVisible((p) => !p)}
        className="menu-button-outer"
      >
        {/* <FontAwesomeIcon icon={faBars} className="menu-button" /> */}
        <img src={menuLogo} alt="menu-logo" className="menu-button" />

      </button>
      {/* logo */}
      <Link to="/">
        <img src={logo} alt="curomates-logo" className="logo" />
      </Link>

      {/* navbar */}
      <nav className={`nav ${isNavVisible ? "display" : "hide"}`}>
      <Link
          to="/"
          className={`gradient-line ${
            currentPath == "/" ? "active" : ""
          }`}
          onClick={() => setIsNavVisible(p => !p)}
        >
          Home
        </Link>
        <Link
          to="/corporate"
          className={`gradient-line ${
            currentPath.includes("/corporate") ? "active" : ""
          }`}
          onClick={() => setIsNavVisible(p => !p)}
        >
          Corporate
        </Link>
        <Link
          to="/about-us"
          className={`gradient-line ${
            currentPath.includes("/about-us") ? "active" : ""
          }`}
          onClick={() => setIsNavVisible(p => !p)}
        >
          About Us
        </Link>
        <Link
          to="/blogs"
          className={`gradient-line ${
            currentPath.includes("/blogs") ? "active" : ""
          }`}
          onClick={() => setIsNavVisible(p => !p)}
        >
          Blogs
        </Link>
        <Link
          to="/faq"
          className={`gradient-line ${
            currentPath.includes("/faq") ? "active" : ""
          }`}
          onClick={() => setIsNavVisible(p => !p)}
        >
          FAQ
        </Link>
        {/* <Link
          to="/careers"
          className={`gradient-line ${
            currentPath.includes("/careers") ? "active" : ""
          }`}
          onClick={() => setIsNavVisible(p => !p)}
        >
          Careers
        </Link> */}

        {/* Download Button */}
        <a
          href="https://play.google.com/store/apps/details?id=com.curomates.mobile"
          target="_blank"
          rel="noopener noreferrer"
        >
          <DownloadButton />
        </a>
      </nav>
    </header>
  );
}
