import React from "react";
import "../components/legal_documents/TermsOfService.css";

export default function PrivacyPolicy() {
  return (
    <div className="tos-container">
      <h2 className="para-heading">PRIVACY POLICY</h2>
      <h3 className="date">Last updated Dec 21, 2023</h3>

      <summary className="paragraph black">
        <p>
          Thank you for choosing to be part of our community at Curomates
          ("Company", "we", "us", "our"). We are committed to protecting your
          personal information and your right to privacy. If you have any
          questions or concerns about this privacy notice, or our practices with
          regards to your personal information, please contact us at
          support@curomates.com.
        </p>
        <p>
          When you visit our website{" "}
          <a
            href="https://www.curomates.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.curomates.com/
          </a>{" "}
          (the "Website"), and more generally, use any of our services (the
          "Services", which include the Website and Mobile Application(s)), we
          appreciate that you are trusting us with your personal information. We
          take your privacy very seriously. In this privacy notice, we seek to
          explain to you in the clearest way possible what information we
          collect, how we use it and what rights you have in relation to it. We
          hope you take some time to read through it carefully, as it is
          important. If there are any terms in this privacy notice that you do
          not agree with, please discontinue use of our Services immediately.
        </p>
        <p>
          This privacy notice applies to all information collected through our
          Services (which, as described above, includes our Website), as well
          as, any related services, sales, marketing or events.
        </p>
      </summary>

      <h2 className="para-heading">
        Please read this privacy notice carefully as it will help you understand
        what we do with the information that we collect.
      </h2>

      <h2 className="para-subheading black">
        1. WHAT INFORMATION DO WE COLLECT?
      </h2>
      <p className="black bold"> Personal information you disclose to us</p>

      <summary className="paragraph black">
        <i className="in-short">
          <strong>In Short:</strong> We collect personal information that you
          provide to us.
        </i>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Website, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Website (such as by posting messages
          in our online forums or entering competitions, contests or giveaways)
          or otherwise when you contact us.
        </p>
        <p>
          The personal information that we collect depends on the context of
          your interactions with us and the Website, the choices you make and
          the products and features you use. The personal information we collect
          may include the following:
        </p>
        <p>
          <strong>Personal Information Provided by You.</strong> We collect
          names; phone numbers; email addresses; usernames; passwords; contact
          or authentication data; and other similar information.
        </p>
        <p>
          <strong>Social Media Login Data. </strong> We may provide you with the
          option to register with us using your existing social media account
          details, like your Facebook, Twitter or other social media account. If
          you choose to register in this way, we will collect the information
          described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?"
          below.
        </p>

        <p>
          All personal information that you provide to us must be true, complete
          and accurate, and you must notify us of any changes to such personal
          information.
        </p>
      </summary>
      <p className="black bold"> Information automatically collected</p>
      <summary className="paragraph black">
        <i className="in-short">
          <strong>In Short:</strong>Some information — such as your Internet
          Protocol (IP) address and/or browser and device characteristics — is
          collected automatically when you visit our Website.{" "}
        </i>
        <p>
          We automatically collect certain information when you visit, use or
          navigate the Website. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Website and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Website, and for our internal analytics and reporting purposes.
        </p>
        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies.{" "}
        </p>
        <p>The information we collect includes:</p>
        <p>
          <ul>
            <li>
              Log and Usage Data: Log and usage data is service-related,
              diagnostic, usage and performance information our servers
              automatically collect when you access or use our Website and which
              we record in log files. Depending on how you interact with us,
              this log data may include your IP address, device information,
              browser type and settings and information about your activity in
              the Website (such as the date/time stamps associated with your
              usage, pages and files viewed, searches and other actions you take
              such as which features you use), device event information (such as
              system activity, error reports (sometimes called 'crash dumps')
              and hardware settings).
            </li>
            <li>
              Device Data: We collect device data such as information about your
              computer, phone, tablet or other device you use to access the
              Website. Depending on the device used, this device data may
              include information such as your IP address (or proxy server),
              device and application identification numbers, location, browser
              type, hardware model Internet service provider and/or mobile
              carrier, operating system and system configuration information.
            </li>
            <li>
              Location Data: We collect location data such as information about
              your device's location, which can be either precise or imprecise.
              How much information we collect depends on the type and settings
              of the device you use to access the Website. For example, we may
              use GPS and other technologies to collect geolocation data that
              tells us your current location (based on your IP address). You can
              opt out of allowing us to collect this information either by
              refusing access to the information or by disabling your Location
              setting on your device. Note however, if you choose to opt out,
              you may not be able to use certain aspects of the Services.
            </li>
            <li>
              If you apply to be a verified listener, we may ask for a
              government ID to be able to verify your identity and age. We will
              store this data for as long as you are a listener on our platform.
              If you withdraw from being a verified listener, either by
              expressing your wish to us or remaining unavailable on the app for
              1 year, we will delete your personal information within 1 year.
            </li>
          </ul>
        </p>
      </summary>
      <p className="black bold">Information collected from other sources </p>

      <summary className="paragraph black">
        <i className="in-short">
          <strong>In Short:</strong> We may collect limited data from public
          databases, marketing partners, social media platforms, and other
          outside sources.
        </i>
        <p>
          In order to enhance our ability to provide relevant marketing, offers
          and services to you and update our records, we may obtain information
          about you from other sources, such as public databases, joint
          marketing partners, affiliate programs, data providers, social media
          platforms, as well as from other third parties. This information
          includes mailing addresses, job titles, email addresses, phone
          numbers, intent data (or user behavior data), Internet Protocol (IP)
          addresses, social media profiles, social media URLs and custom
          profiles, for purposes of targeted advertising and event promotion. If
          you interact with us on a social media platform using your social
          media account (e.g. Facebook or Twitter), we receive personal
          information about you such as your name, email address, and gender.
          Any personal information that we collect from your social media
          account depends on your social media account's privacy settings.
        </p>
      </summary>
      <h2 className="para-subheading black">
        2. HOW DO WE USE YOUR INFORMATION?
      </h2>

      <summary className="paragraph black">
        <i className="in-short">
          <strong>In Short:</strong> We process your information for purposes
          based on legitimate business interests, the fulfillment of our
          contract with you, compliance with our legal obligations, and/or your
          consent.
        </i>
        <p>
          Your email or phone number is not visible to other users of the
          platform.
        </p>
        <p>We use the information we collect or receive:</p>
        <p>
          <ul>
            <li>
              <strong className="black bold">
                To facilitate account creation and logon process.
              </strong>{" "}
              If you choose to link your account with us to a third-party
              account (such as your Google or Facebook account), we use the
              information you allowed us to collect from those third parties to
              facilitate account creation and logon process for the performance
              of the contract. See the section below headed "HOW DO WE HANDLE
              YOUR SOCIAL LOGINS?" for further information.
            </li>
            <li>
              <strong className="black bold">To post testimonials.</strong> We
              post testimonials on our Website that may contain personal
              information. Prior to posting a testimonial, we will obtain your
              consent to use your name and the content of the testimonial. If
              you wish to update, or delete your testimonial, please contact us
              at support@curomates.com and be sure to include your name,
              testimonial location, and contact information.
            </li>
            <li>
              <strong className="black bold">Request feedback.</strong> We may
              use your information to request feedback and to contact you about
              your use of our Website. We may also contact you outside of the
              Curomates app using your mobile number/associated WhatsApp account
              or email for feedback about the app.
            </li>
            <li>
              <strong className="black bold">
                To enable user-to-user communications.
              </strong>{" "}
              We may use your information in order to enable user-to-user
              communications with each user's consent.
            </li>
            <li>
              <strong className="black bold">To manage user accounts.</strong>{" "}
              We may use your information for the purposes of managing our
              account and keeping it in working order.
            </li>
            <li>
              <strong className="black bold">
                To send administrative information to you.
              </strong>{" "}
              We may use your personal information to send you product, service
              and new feature information and/or information about changes to
              our terms, conditions, and policies.
            </li>
            <li>
              <strong className="black bold">To protect our Services.</strong>{" "}
              We may use your information as part of our efforts to keep our
              Website safe and secure (for example, for fraud monitoring and
              prevention).
            </li>
            <li>
              <strong className="black bold">
                To enforce our terms, conditions and policies for business
                purposes, to comply with legal and regulatory requirements or in
                connection with our contract.
              </strong>
            </li>
            <li>
              <strong className="black bold">
                To respond to legal requests and prevent harm.
              </strong>{" "}
              If we receive a subpoena or other legal request, we may need to
              inspect the data we hold to determine how to respond.
            </li>
            <li>
              <strong className="black bold">
                Fulfill and manage your orders.
              </strong>{" "}
              We may use your information to fulfill and manage your orders,
              payments, returns, and exchanges made through the Website.
            </li>
            <li>
              <strong className="black bold">
                Administer prize draws and competitions.
              </strong>{" "}
              We may use your information to administer prize draws and
              competitions when you elect to participate in our competitions.
            </li>
            <li>
              <strong className="black bold">
                To deliver and facilitate delivery of services to the user.
              </strong>{" "}
              We may use your information to provide you with the requested
              service.
            </li>
            <li>
              <strong className="black bold">
                To respond to user inquiries/offer support to users.
              </strong>{" "}
              We may use your information to respond to your inquiries and solve
              any potential issues you might have with the use of our Services.
            </li>
            <li>
              <strong className="black bold">
                To send you marketing and promotional communications.
              </strong>{" "}
              We and/or our third-party marketing partners may use the personal
              information you send to us for our marketing purposes, if this is
              in accordance with your marketing preferences. For example, when
              expressing an interest in obtaining information about us or our
              Website, subscribing to marketing or otherwise contacting us, we
              will collect personal information from you. You can opt-out of our
              marketing emails at any time (see the "WHAT ARE YOUR PRIVACY
              RIGHTS?" below).
            </li>
            <li>
              <strong className="black bold">
                Deliver targeted advertising to you.
              </strong>{" "}
              We may use your information to develop and display personalized
              content and advertising (and work with third parties who do so)
              tailored to your interests and/or location and to measure its
              effectiveness.
            </li>
            <li>
              <strong className="black bold">
                For other business purposes.
              </strong>{" "}
              We may use your information for other business purposes, such as
              data analysis, identifying usage trends, determining the
              effectiveness of our promotional campaigns and to evaluate and
              improve our Website, ask for feedback, products, marketing and
              your experience. We may use and store this information in
              aggregated and anonymized form so that it is not associated with
              individual end users and does not include personal information. We
              will not use identifiable personal information without your
              consent.
            </li>
          </ul>
        </p>
      </summary>
      <h2 className="para-heading">
        3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
      </h2>

      <summary className="paragraph black">
        <i className="in-short">
          <strong>In Short:</strong>
          We only share information with your consent, to comply with laws, to
          provide you with services, to protect your rights, or to fulfill
          business obligations.
        </i>
        <p>
          We may process or share your data that we hold based on the following
          legal basis:
        </p>
        <p>
          <ul>
            <li>
              <strong className="black bold">Consent:</strong> We may process
              your data if you have given us specific consent to use your
              personal information for a specific purpose.
            </li>
            <li>
              <strong className="black bold">Legitimate Interests:</strong> We
              may process your data when it is reasonably necessary to achieve
              our legitimate business interests.
            </li>
            <li>
              <strong className="black bold">Performance of a Contract:</strong>{" "}
              Where we have entered into a contract with you, we may process
              your personal information to fulfill the terms of our contract.
            </li>
            <li>
              <strong className="black bold">Legal Obligations:</strong> We may
              disclose your information where we are legally required to do so
              in order to comply with applicable law, governmental requests, a
              judicial proceeding, court order, or legal process, such as in
              response to a court order or a subpoena (including in response to
              public authorities to meet national security or law enforcement
              requirements).
            </li>
            <li>
              <strong className="black bold">Vital Interests:</strong> We may
              disclose your information where we believe it is necessary to
              investigate, prevent, or take action regarding potential
              violations of our policies, suspected fraud, situations involving
              potential threats to the safety of any person and illegal
              activities, or as evidence in litigation in which we are involved.
            </li>
            <strong className="black bold">
              More specifically, we may need to process your data or share your
              personal information in the following situations:
            </strong>
            <li>
              <strong className="black bold">Business Transfers:</strong> We may
              share or transfer your information in connection with, or during
              negotiations of, any merger, sale of company assets, financing, or
              acquisition of all or a portion of our business to another
              company.
            </li>
            <li>
              <strong className="black bold">Google Maps Platform APIs:</strong>{" "}
              We may share your information with certain Google Maps Platform
              APIs (e.g., Google Maps API, Place API). To find out more about
              Google’s Privacy Policy, please refer to this{" "}
              <a href="https://policies.google.com/privacy">link</a>. We obtain
              and store on your device ('cache') your location. You may revoke
              your consent anytime by contacting us at the contact details
              provided at the end of this document.
            </li>
            <li>
              <strong className="black bold">
                Vendors, Consultants and Other Third-Party Service Providers:
              </strong>{" "}
              We may share your data with third-party vendors, service
              providers, contractors, or agents who perform services for us or
              on our behalf and require access to such information to do that
              work. Examples include: payment processing, data analysis, email
              delivery, hosting services, customer service, and marketing
              efforts. We may allow selected third parties to use tracking
              technology on the Website, which will enable them to collect data
              on our behalf about how you interact with our Website over time.
              This information may be used to, among other things, analyze and
              track data, determine the popularity of certain content, pages or
              features, and better understand online activity. Unless described
              in this notice, we do not share, sell, rent, or trade any of your
              information with third parties for their promotional purposes.
            </li>
            <li>
              <strong className="black bold">Other Users:</strong> When you
              share personal information (for example, by posting comments,
              contributions, or other content to the Website) or otherwise
              interact with public areas of the Website, such personal
              information may be viewed by all users and may be publicly made
              available outside the Website in perpetuity. If you interact with
              other users of our Website and register for our Website through a
              social network (such as Facebook), your contacts on the social
              network will see your name, profile photo, and descriptions of
              your activity. Similarly, other users will be able to view
              descriptions of your activity, communicate with you within our
              Website, and view your profile.
            </li>
          </ul>
        </p>
      </summary>

      <h2 className="para-heading">
        4. WHO WILL YOUR INFORMATION BE SHARED WITH?
      </h2>

      <summary className="paragraph black">
        <i className="in-short">
          <strong>In Short:</strong>
          We only share information with the following categories of third
          parties.
        </i>
        <p>
          We only share and disclose your information with the following
          categories of third parties. If we have processed your data based on
          your consent and you wish to revoke your consent,
        </p>
        <p>
          <ul>
            <li>Data Analytics Services</li>
            <li>Data Storage Service Providers</li>
            <li>Retargeting Platforms</li>
            <li>Sales &amp; Marketing Tools</li>
            <li>Social Networks</li>
            <li>Testing Tools</li>
            <li>User Account Registration &amp; Authentication Services</li>
            <li>Website Hosting Service Providers</li>
          </ul>
        </p>
      </summary>
      <h2 className="para-heading">
        5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
      </h2>
      <i className="in-short">
        <strong>In Short:</strong>
        We may use cookies and other tracking technologies to collect and store
        your information.
      </i>

      <summary className="paragraph black">
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice.
        </p>
      </summary>
      <h2 className="para-heading">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>

      <summary className="paragraph black">
        <i className="in-short">
          <strong>In Short:</strong> If you choose to register or log in to our
          services using a social media account, we may have access to certain
          information about you.
        </i>
        <p>
          Our Website offers you the ability to register and login using your
          third-party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          friends list, profile picture as well as other information you choose
          to make public on such social media platform.{" "}
        </p>
        <p>
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Website. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </p>
      </summary>
      <h2 className="para-heading">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
      <p className="black bold"> </p>

      <summary className="paragraph black">
        <i className="in-short">
          <strong>In Short:</strong> We keep your information for as long as
          necessary to fulfill the purposes outlined in this privacy notice
          unless otherwise required by law.
        </i>
        <p>
          <i>
            To request a deletion of an account, users can email us a request at{" "}
            <strong> support@curomates.com </strong>
          </i>
        </p>
        <p>
          Currently, users do not have the ability to delete their accounts and
          their data stored on our platform themselves. However, we are working
          on this feature and will bring it in a subsequent update.
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting or other legal requirements).
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
      </summary>
      <h2 className="para-heading">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>

      <summary className="paragraph black">
        <i className="in-short">
          <strong>In Short:</strong> We aim to protect your personal information
          through a system of organizational and technical security measures.
        </i>
        <p>
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any personal information
          we process. However, despite our safeguards and efforts to secure your
          information, no electronic transmission over the Internet or
          information storage technology can be guaranteed to be 100% secure, so
          we cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorized third parties will not be able to defeat our security,
          and improperly collect, access, steal, or modify your information.
          Although we will do our best to protect your personal information,
          transmission of personal information to and from our Website is at
          your own risk. You should only access the Website within a secure
          environment.
        </p>
      </summary>
      <h2 className="para-heading">
        9. DO WE COLLECT INFORMATION FROM MINORS?
      </h2>

      <summary className="paragraph black">
        <i className="in-short">
          <strong>In Short:</strong> We do not knowingly collect data from or
          market to children under 18 years of age.
        </i>
        <p>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Website, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent’s use of the Website. If we learn that
          personal information from users less than 18 years of age has been
          collected, we will deactivate the account and take reasonable measures
          to promptly delete such data from our records. If you become aware of
          any data we may have collected from children under age 18, please
          contact us at support@curomates.com.
        </p>
      </summary>
      <h2 className="para-heading">10. WHAT ARE YOUR PRIVACY RIGHTS?</h2>

      <summary className="paragraph black">
        <i className="in-short">
          <strong>In Short:</strong> You may review, change, or terminate your
          account at any time by contacting us at support@curomates.com
        </i>
        <p>
          If you have questions or comments about your privacy rights, you may
          email us at support@curomates.com.
        </p>

        <p className="black bold">Account Information </p>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:{" "}
          <ul>
            <li>
              <p> Contact us using the contact information provided. </p>{" "}
            </li>
          </ul>
          <p>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our Terms of Use and/or comply with applicable legal
            requirements.
          </p>
          <p>
            <strong> Cookies and similar technologies:</strong> Most Web
            browsers are set to accept cookies by default. If you prefer, you
            can usually choose to set your browser to remove cookies and to
            reject cookies. If you choose to remove cookies or reject cookies,
            this could affect certain features or services of our Website.
          </p>{" "}
          <p>
            {" "}
            <strong>Opting out of email marketing:</strong> You can unsubscribe
            from our marketing email list at any time by clicking on the
            unsubscribe link in the emails that we send or by contacting us
            using the details provided below. You will then be removed from the
            marketing email list — however, we may still communicate with you,
            for example to send you service-related emails that are necessary
            for the administration and use of your account, to respond to
            service requests, or for other non-marketing purposes. To otherwise
            opt-out, you may: Contact us using the contact information provided.
          </p>
          <ul>
            <li>
              <p> Contact us using the contact information provided. </p>{" "}
            </li>
          </ul>
        </p>
      </summary>

      <h2 className="para-heading">11. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>

      <summary className="paragraph black">
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.{" "}
        </p>
      </summary>

      <h2 className="para-heading">13. DO WE MAKE UPDATES TO THIS NOTICE? </h2>
      <summary className="paragraph black">
        <i className="in-short">
          <strong>In Short:</strong> Yes, we will update this notice as
          necessary to stay compliant with relevant laws.
        </i>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>
        <h2 className="para-heading">
          14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?{" "}
        </h2>
        <p>
          If you have questions or comments about this notice, you may email us
          at support@curomates.com or by post to:
        </p>
      </summary>

      <h2 className="para-heading">
        15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </h2>
      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it in some circumstances. To request to
        review, update, or delete your personal information, please visit:
        support@curomates.com.
      </p>
    </div>
  );
}
