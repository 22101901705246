import React, { useState } from "react";
import { getBlogByParam, getRandom } from "../../../utils/utils";
import { articles } from "../../../data/data";
import InfoBar from "./InfoBar";
import "./Post.css";
import BlogsListCard from "../BlogsListCard";
import likeButton from "../../../assets/blogs/heart-like-filled.png";
import notLikedButton from "../../../assets/blogs/heart-like-empty.png";

export default function CopingWithRejection({ url_param }) {
  const { title, imageURL, author, dateUploaded } = getBlogByParam(
    articles,
    url_param
  );
  const [isLiked, setIsLiked] = useState(false);
  const randomNum = getRandom(11);
  const handleLikeClick = () => setIsLiked((p) => !p);

  return (
    <div className="post">
      <div className="post-container">
        <h1 className="post-heading">{title}</h1>
        <InfoBar author={author} dateUploaded={dateUploaded} />
        <img src={`${imageURL}`} alt="blog-cover" />
        {/* MAIN CONTENT */}
        <summary className="content-container">
          <div className="section">
            <h1 className="section-title">Coping With Rejection</h1>
            <p className="section-content">
              Do you remember how it felt being chosen last for the basketball
              team in school, not being invited to a social event, failing to
              land the job you interviewed for or perhaps being ghosted by your
              crush? Pretty awful right? No matter what stage of life you are
              in, or how old you get, rejection never fails to be disheartening,
              leaving you confused, disappointed and self-critical. It causes us
              to feel unwanted and not good enough.
            </p>
            <p className="section-content">But why does rejection hurt so much?</p>
            <p className="section-content">
              If this is a question that persists to bother you, you will be
              glad to know you are not alone!
            </p>
            <p className="section-content">
              As researchers explored this question further, they found
              astonishing evidence that the pain you feel when being excluded is
              actually comparable to the pain of physical injury!
            </p>
            <p className="section-content">
              Naomi Eisenberger, Kipling Williams and colleagues found that
              social rejection activates many of the same brain regions involved
              in physical pain.
            </p>
            <p className="section-content">
              To study rejection inside an fMRI scanner, the researchers used a
              technique called Cyberball, where the participant plays a game of
              catch online with two other players, who eventually stop passing
              the ball to each other, excluding the participant. Compared with
              subjects who continue to be included, those who are rejected show
              increased activity in the dorsal anterior cingulate and the
              anterior insular — two of the regions that show increased activity
              in response to physical pain, Eisenberger says, “As far as your
              brain is concerned, a broken heart is not so different from a
              broken arm.”
            </p>
            <p className="section-content">
              Moreover, rejection increases anger, anxiety, depression, jealousy
              and sadness. It can reduce our abilities to perform challenging
              cognitive tasks, and can also result in aggression and poor
              self-control. It can also take a toll on our physical health,
              wherein people who constantly feel excluded experience sleep
              problems and poorer functioning of their immune systems as
              compared to those who feel included. Hence, it is clear that
              rejection is bound to make us feel miserable no matter how
              resilient we are.
            </p>
            <p className="section-content">So what do we do, then?</p>
            <p className="section-content">
              While we cannot help that rejection will always be painful, we can
              work on altering our responses to it. Yes, it did take time to
              heal from that bad break-up or job loss, but you do eventually get
              over the initial pain and hurt feelings of rejection. By
              implementing the following steps, you might be able to make those
              feelings a little easier to bear.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Question the self-criticism</h2>
            <p className="section-content">
              The first thing we ask ourselves when being rejected is, “But,
              why?”, a question that usually yields no satisfying answers. This
              leads to a downward spiral of blame and negative self-talk, which
              only makes us feel worse. Instead of this, take a moment to
              observe these negative thoughts, and challenge them. For example,
              if you have thoughts like “I am unlikeable” after not being
              invited to a party, acknowledge that this is simply a passing
              thought. Remind yourself that you can feel unlikeable and still be
              very likeable. Your thoughts and feelings are not facts.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Affirm your self-worth</h2>
            <p className="section-content">
              List down a few things you like about yourself. It can be
              something as small as the fact that you love animals, enjoy
              helping your friends out, or anything that makes you, you. Keep
              this list handy, and read it aloud when you wake up, or whenever
              you feel down. This exercise will help you remember who you truly
              are, especially during moments of self-doubt.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Lean on the people you love</h2>
            <p className="section-content">
              Rejection can often feel like being cut off from the world and
              dismissed by the universe. But, no matter how harsh the rejection
              was, there are always people on your side, rooting for you. Remind
              yourself this by reaching out to your friends and family,
              strengthening connections with the people around you.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Try again!</h2>
            <p className="section-content">
              Did you know that Walt Disney was fired by his editor because he
              “lacked imagination and had no good ideas?”. It seems that if
              there is one thing common amongst legends, himself included, it is
              that many of them faced painful rejections on their road to
              worldwide success. Resilience, that is, the ability to bounce back
              from setbacks, is an invaluable quality that most brilliant people
              we know possess. And, guess what? Good news! Psychologists have
              confirmed that resilience isn’t necessarily a personality trait
              that only some people possess. On the contrary, resilience
              involves behaviours, thoughts, and actions that anyone can learn
              and develop. Therefore, the next time you are turned down, remind
              yourself that rejection happens to everyone, and ask yourself what
              you can do moving forward. Questions like, “Maybe there is
              something I could learn from this experience” or, “ How might I do
              this differently in the future” will greatly help you improve
              further.
            </p>
            <p className="section-content">
              Hence, rejection is pretty much par for the course where success
              is concerned. The wins would not be as satisfying without the
              drawbacks. It only makes victory sweeter. Through your journey
              towards achieving your goals, remember that you are far more than
              your moments of failure. Rejections will never define who you are,
              and are nothing but opportunities for further growth and
              achievements.
            </p>
          </div>
        </summary>
      </div>

      <div className="post-bottom">
        <div className="likes">
          {isLiked ? (
            <button onClick={handleLikeClick}>
              <img src={likeButton} alt="heart-filled-button" width={30} />
            </button>
          ) : (
            <button onClick={handleLikeClick}>
              <img src={notLikedButton} alt="heart-empty-button" width={30} />
            </button>
          )}
        </div>
        <h2 style={{ fontWeight: 200 }}>More</h2>
        {articles.slice(randomNum, randomNum + 3).map((item, index) => {
          return (
            <BlogsListCard
              author={item.author}
              dateUploaded={item.dateUploaded}
              imageURL={item.imageURL}
              title={item.title}
              url_param={item.url_param}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}
