import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const DeleteAccountPage = () => {
  const [formData, setFormData] = useState({
    username: '',
    reason: '',
    moreInfo: '',
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.username) newErrors.username = 'Username is required';
    if (!formData.reason) newErrors.reason = 'Reason is required';
    if (!formData.moreInfo) newErrors.moreInfo = 'Please provide more information';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      const api = "https://asia-south1-curomates-prod.cloudfunctions.net/api/deleteAccountRequest";
      try {
        const response = await axios.post(api, formData, {
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (response.status === 200) {
          toast.success("Thanks, we will reach out to you soon!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setFormData({
            username: '',
            reason: '',
            moreInfo: '',
          });
        } else {
          console.error('Error:', response.statusText);
          toast.error(`Error: ${response.statusText}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error(`Error: ${error.message}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  return (
    <div style={styles.container}>
      <h3 style={styles.header}>Delete Account</h3>
      <p style={styles.description}>
        We are sorry to hear that you are considering leaving Curomates 🥲. Your presence and contributions have been invaluable to us. 
        {"\n"}Your feedback will help us improve our community and better support our members in the future.
      </p>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.formGroup}>
          <label style={styles.label}>Username:</label>
          <input
            placeholder="Enter your username"
            type="text"
            value={formData.username}
            onChange={(e) => setFormData({ ...formData, username: e.target.value })}
            style={styles.input}
          />
          {errors.username && <span style={styles.error}>{errors.username}</span>}
        </div>
        
        <div style={styles.formGroup}>
          <label style={styles.label}>Please select reasons for deleting your account:</label>
          <select
            value={formData.reason}
            onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
            style={styles.select}
          >
            <option value="" disabled>Select a reason</option>
            <option value="I was looking for something else">I was looking for something else</option>
            <option value="Did not like the app">Did not like the app</option>
            <option value="Found better alternative">Found better alternative</option>
            <option value="Did not like the community members">Did not like the community members</option>
            <option value="Don't need the app now">Don't need the app now</option>
            <option value="App has a lot of issues">App has a lot of issues</option>
            <option value="App is not meeting my expectations">App is not meeting my expectations</option>
            <option value="Other">Other</option>
          </select>
          {errors.reason && <span style={styles.error}>{errors.reason}</span>}
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Tell us more about it:</label>
          <textarea
            placeholder="Please tell us in brief why you are leaving the platform"
            value={formData.moreInfo}
            onChange={(e) => setFormData({ ...formData, moreInfo: e.target.value })}
            style={styles.textarea}
          />
          {errors.moreInfo && <span style={styles.error}>{errors.moreInfo}</span>}
        </div>

        <button type="submit" style={styles.button}>Submit</button>
      </form>
      <ToastContainer />
    </div>
  );
};

const styles = {

    container: {
      
      maxWidth: '900px',
      paddingBottom: '20px',
      paddingLeft: '20px',
      paddingRight: '20px',
      margin: '2rem auto',

      backgroundColor: '#f9f9f9',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    header: {
      paddingTop:'2rem',
      paddingBottom:'2rem',
      fontSize: '2rem',
      textAlign: 'center',
      color: '#333',
    },
    description: {
      textAlign: 'center',
      color: '#666',
      marginBottom: '20px',
      
    },
    form: {
      display:'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      marginLeft: '2px',
      marginBottom: '6px',
      color: '#333',
      fontWeight: 'bold',
    },
    input: {
      padding: '10px',
      fontSize: '16px',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    select: {
      padding: '10px',
      fontSize: '16px',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    textarea: {
      padding: '10px',
      fontSize: '16px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      minHeight: '100px',
    },
    button: {
      marginTop: '10px',
      padding: '10px 20px',
      fontSize: '16px',
      color: '#fff',
      background: 'linear-gradient(to right, #ff4d4d, #ff7e5f)',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      alignSelf: 'center',
    },
    error: {
      color: 'red',
      marginTop: '5px',
    },
  
};
export default DeleteAccountPage;