import React, { useState } from "react";
import { getBlogByParam, getRandom } from "../../../utils/utils";
import { articles } from "../../../data/data";
import InfoBar from "./InfoBar";
import "./Post.css";
import BlogsListCard from "../BlogsListCard";
import likeButton from "../../../assets/blogs/heart-like-filled.png";
import notLikedButton from "../../../assets/blogs/heart-like-empty.png";

export default function ARoadToBeTakenSelfLove2({ url_param }) {
  const { title, imageURL, author, dateUploaded } = getBlogByParam(
    articles,
    url_param
  );
  const [isLiked, setIsLiked] = useState(false);
  const randomNum = getRandom(11);
  const handleLikeClick = () => setIsLiked((p) => !p);

  return (
    <div className="post">
      <div className="post-container">
        <h1 className="post-heading">{title}</h1>
        <InfoBar author={author} dateUploaded={dateUploaded} />
        <img src={`${imageURL}`} alt="blog-cover" />
        {/* MAIN CONTENT */}
        <summary className="content-container">
          <div className="section">
            <p className="section-content">Part of Self-Love: A Series</p>
            <p className="section-content">
              Practicing self-love appears to be easier said than done, and
              there are numerous reasons why it appears to be so difficult in
              the first place.
            </p>
            <p className="section-content">
              The cultural connotation of self-love is frequently selfishness
              and narcissism. Some of us may be terrified of coming across as
              self-absorbed or overconfident, while others have never been
              taught the concept of "the self" and place altruism as the highest
              honor. However one looks at it, self-love is harder to accept than
              it is to preach.
            </p>
            <p className="section-content">
              The way self-love can affect our wellness can be compared to
              working out or eating good wholesome food.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">How to Practice Self-love</h2>
            <p className="section-content">
              Here are some ways one can engage on their path to self-love:
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">
              Treat yourself, the way you treat your friend
            </h2>
            <p className="section-content">
              I think most of us are guilty of being horrible to ourselves, that
              includes me too. We are so used to setting the bar for ourselves
              so high that we often forget we need to be patient and tolerant
              with ourselves, the same way you would extend your support to a
              friend.
            </p>
            <p className="section-content">
              To be honest, this also applies when you realize you've been harsh
              on yourself and begin to feel extremely guilty about it, so you
              keep being self-critical as a part of an extended cycle. We are
              all human and make mistakes, but we can start working on ourselves
              now rather than later.
            </p>
            <p className="section-content">
              Frankly speaking, we should all be a little kinder to ourselves.
              Next time you recognize that you are being mean to yourselves, I
              need you to stop, wait a minute, and think if it would be the same
              conversation you would be having with a friend going through the
              same thing as you were.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">
              Stick to your healthy Personal Boundaries
            </h2>
            <p className="section-content">
              One of the best things you can do for yourself is to set down some
              personal boundaries, but from personal experience, it's also one
              of the most difficult things to do when you've spent most of your
              life as a fellow over-extender, people-pleaser, and those in
              pursuit of external validations.
            </p>
            <p className="section-content">
              Especially, the people around you, the ones that occupy your
              personal space the most, will be the first ones to see the
              changes, and some may be pleased while others won't. It is
              critical to make the space and time to create what will help you
              grow as a person and protect your mental health. The more you grow
              older, the more you'll value having personal boundaries.
            </p>
            <p className="section-content">
              It is pivotal that we begin to understand what one truly desires
              so that we can begin to work on it. Say 'no' when you have to,
              refuse to invest in situations and people that clearly are red
              flags for you, and ask for emotional or other needs that you'd
              like catered by your loved ones.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Changing your critical self-talk</h2>
            <p className="section-content">
              Do you constantly tell yourself that you've failed yourself by
              failing to complete a minor task? Exaggerating a situation and
              labeling yourself as a rejection or the like?
            </p>
            <p className="section-content">
              'Negative self-talk' is that voice in our heads that happens to be
              extremely negative to ourselves, becomes the harshest critic
              you've ever encountered, and charges at you with doubt, fear, and
              judgment. Once the cycle of negative self-talk is unleashed and
              becomes constant, it's hard to hear anything amongst that white
              noise.
            </p>
            <p className="section-content">
              As the older we get, the value of our self-talk only gets more
              concretized and, it starts to feel that if 'I feel this way, it
              must be true.' In actuality, our feelings, thoughts, and facts may
              not always align.
            </p>
            <p className="section-content">
              Negative self-talk evolved as a way to prepare ourselves for the
              worst in order to survive, but it has since outgrown its utility.
              Extreme self-criticism can be a result of a childhood in which you
              were constantly criticized and judged, and you learned to
              internalize the external criticisms over time.
            </p>
            <p className="section-content">
              So, better late than never, developing a stronger sense of
              self-love, self-compassion, and self-worth will allow us to be
              kinder to ourselves. And understand that you are not alone and
              many others struggle with critical self-talk and that you will
              slip up, but I guess we just have to keep trying.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">
              Self-compassion breaks and Self-care
            </h2>
            <p className="section-content">
              If you are one of those fellow friends who never take a
              well-deserved break from studies, work, or the capitalist hustle
              culture, then this one is for you.
            </p>
            <p className="section-content">
              In today's world, I hear from my hard-at-work friends about how
              they have worked on a pitch overnight and how they rarely catch on
              to proper nutrition; some of them even speak highly of it almost
              as a remark to testify to their hard work and dedication. And, to
              be honest, I am guilty as charged.
            </p>
            <p className="section-content">
              From personal experience, the more you exert yourself on a regular
              basis, the closer you are to exhaustion. Self-love arises from the
              desire to care for oneself because no one else will. Nobody will
              ever understand how important your overall health is to you.
            </p>
            <p className="section-content">
              Self-love comes from no external validation, and you will love
              yourself if you take care of your needs, and vice versa. Take that
              Kit-Kat break if that's what you want, set a good daily routine,
              get that proper nutrition, get those 10,000 steps a day, meet your
              friends and socialize, take the hike you've been thinking of for
              the past three months or take that optional leave for a long nap
              that you deserve. I know it's much easier said than done, but
              remember that no one will care as much about you as you do.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">
              Embrace Mindfulness, Meditation, and creative outlets
            </h2>
            <p className="section-content">
              Since I began working on myself last year, I've realized that
              taking care of yourself is as much a priority and commitment as
              anything else. It takes time, patience, and empathy to develop
              these patterns, just like it does to learn any other skill for
              work, and it is definitely difficult, but let's be honest, what
              isn't? But with practice, it gets so much easier.
            </p>
            <p className="section-content">
              Few of my colleagues have talked to me about how they've started
              doing things much more mindfully than they used to, and how it's
              helping them. Few people live by meditation and the peace and calm
              it brings. Some people express and explore themselves through
              creative outlets such as writing, journaling, art, and performance
              arts. It's the childlike wonder of discovering what suits you best
              and what you'd like to enjoy, as well as identifying what helps
              you embrace the journey of self-love.
            </p>
            <p className="section-content">
              I acknowledge that mindfulness and meditation may appear
              intimidating and overwhelming to beginners; however, you can get
              started with our Curomates mindfulness meditation audio.
            </p>
          </div>
          <div className="section">
            <h2 className="section-title">Try to let go of Comparisons</h2>
            <p className="section-content">
              It's incredibly difficult not to compare yourself to others. We've
              been compared to others since time immemorial. And then you grow
              up and do it for yourself, comparing yourself to others and their
              successes and pace.
            </p>
            <p className="section-content">
              I believe it is important to remind yourself from time to time
              that we all have our own pace and time, and that we are all so
              different. Different people have different definitions of success,
              different values
            </p>
          </div>
        </summary>
      </div>

      <div className="post-bottom">
        <div className="likes">
          {isLiked ? (
            <button onClick={handleLikeClick}>
              <img src={likeButton} alt="heart-filled-button" width={30} />
            </button>
          ) : (
            <button onClick={handleLikeClick}>
              <img src={notLikedButton} alt="heart-empty-button" width={30} />
            </button>
          )}
        </div>
        <h2 style={{ fontWeight: 200 }}>More</h2>
        {articles.slice(randomNum, randomNum + 3).map((item, index) => {
          return (
            <BlogsListCard
              author={item.author}
              dateUploaded={item.dateUploaded}
              imageURL={item.imageURL}
              title={item.title}
              url_param={item.url_param}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}
